// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meta {
  margin: 10px 0 0;
  max-height: 300px;
  overflow-y: auto;
  background-color: #555;
  border-radius: 12px;
  border: 1px solid beige;
}
.ul-meta {
  display: block;
  padding-inline-start: 0;
  margin: 0;
  /*list-style-type: "✔";*/
  padding: 10px 20px;
  color: white;
}
.ul-meta li {
  /*display:inline;*/
  /*position: relative;*/
  padding: 2px 0;
}
.val-meta {
  background-color: white;
  color: rgb(var(--background-start-rgb));
  border-radius: 7px;
  padding: 2px 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/get-ursus-meta/get-ursus-meta.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,cAAc;EACd,uBAAuB;EACvB,SAAS;EACT,wBAAwB;EACxB,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,cAAc;AAChB;AACA;EACE,uBAAuB;EACvB,uCAAuC;EACvC,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".meta {\n  margin: 10px 0 0;\n  max-height: 300px;\n  overflow-y: auto;\n  background-color: #555;\n  border-radius: 12px;\n  border: 1px solid beige;\n}\n.ul-meta {\n  display: block;\n  padding-inline-start: 0;\n  margin: 0;\n  /*list-style-type: \"✔\";*/\n  padding: 10px 20px;\n  color: white;\n}\n.ul-meta li {\n  /*display:inline;*/\n  /*position: relative;*/\n  padding: 2px 0;\n}\n.val-meta {\n  background-color: white;\n  color: rgb(var(--background-start-rgb));\n  border-radius: 7px;\n  padding: 2px 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
