import React, {useEffect, useState} from 'react'
import './compile-prop.css'
import { keyPress09 } from '../../utils/key-press-09'
import { isTimeout } from '../../utils/timeout'
import Code from '../code'

const CompileProp = ({api, api_, auth, session, onPost, isLoader, files, propositions, curContract}) => {

  const [proposition, setProposition] = useState('')
  const [code, setCode] = useState('')
  const [timeout, setTimeout] = useState(120)
  const [isCompileProp, setIsCompileProp] = useState(false)

  let url = 'compile-prop'

  let json = {
    auth: auth,
    contract: curContract.contractName,
    proposition:  proposition,
    timeout: timeout,
  }

  let jsonRequest = {
    auth: {
      key: "...",
      signature: "...",
      data: "text",
      encoding: auth.encoding
    },
    contract: curContract.contractName,
    proposition:  proposition,
    timeout: timeout
  }

  let jsonResponse = {
    base: url,
    proposition: proposition
  }

  useEffect(() => {
    findCompileProp()
    return () => {
      // Component unmount code.
    }
  }, [files, proposition])

  const findCompileProp = () => {
    const propositionSpecDirectory = '/share/'+session+'/artifacts/coqbuild/'+curContract.contractName+'/Spec'
    const compilePropPath = propositionSpecDirectory + '/' + proposition + '.vo'

    if (proposition.length > 0) {
      //console.log(compilePropPath)
      //console.log(files)

      if (files.findLastIndex(item => item.path === compilePropPath && item.event !== 'delete') >= 0) {
        setIsCompileProp(true)
      } else {
        setIsCompileProp(false)
      }
    }
  }

  const changeProposition = (e) => {
    setProposition(e.target.value)
    let i = propositions.findIndex(item => item.name === e.target.value)
    if (i >= 0) {
      setCode(propositions[i].code)
    } else {
      setCode('')
    }
  }

  const onTimeout = (value) => {
    setTimeout(isTimeout(value))
  }

  return (
    <div className="overflow-hidden content-section" id="content-compile-prop">
      <h2>compile prop</h2>
      <pre>
        <Code api_ = {api_}
              url = {url}
              jsonRequestLabel = "JSON Request"
              jsonResponseLabel = "JSON Response"
              jsonRequest = {jsonRequest}
              jsonResponse = {jsonResponse}
              commentForResponse = "+ asynchronous socket return"
        />

        <code className="json hljs">
          <br/>Code:<br/>
          <span className="code-example">{code}</span>
        </code>

        {/* START select Proposition */}
        <div className="select_">
          <select name="select" onChange={changeProposition}>
            <option key={'optionCP--0'} value="">Select Proposition:</option>
            {Object.keys(propositions).map((key, n) => (
              <React.Fragment key={'optionCP-'+key}>
                <option
                  key={'optionCP--'+key}
                  value={propositions[key].name}
                >
                  {propositions[key].name}{/* - {propositions[key].status}*/}
                </option>
              </React.Fragment>
            ))}
          </select>
          <div className="editor"/>
        </div>
        {/* END select Proposition */}

        <div className="input_">
                  <input type="text"
                         id="ce-timeout"
                         className="add-p-input"
                         value={timeout}
                         pattern="^[0-9]{1,4}" required maxLength="4"
                         onKeyPress={e => keyPress09(e)}
                         onChange={e => onTimeout(e.target.value)}
                  />
          <div className="editor"/>
        </div>

        <button
          key={url}
          className="api-btn"
          type="button"
          disabled={isLoader || isCompileProp || proposition.length === 0 || timeout.length === 0 || timeout < 1}
          onClick={(event) => onPost(event, url, json)}
        >
           Compile Prop
        </button>
        {isCompileProp && (
          <div className="check"> </div>
        )}
      </pre>
      <p>
        Compile translated Ursus file.<br/>
        You need to make a POST call to the following url:<br/>
        <i>/{url}</i>
      </p>
      <br/>
      <h4>QUERY PARAMETERS</h4>
      <table className="central-overflow-x">
        <thead>
        <tr>
          <th>Field</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>auth*</td>
          <td>Object</td>
          <td>Authorization</td>
        </tr>
        <tr>
          <td>function*</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>proposition*</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>timeout*</td>
          <td>Number</td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <table className="sub-table">
        <thead></thead>
        <tbody>
        <tr>
          <td>* - required</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CompileProp;
