import React from 'react'
import './kill-all-sessions.css'
import Code from '../code'

const KillAllSessions = ({api, api_, auth, session, onPost, isLoader}) => {
  let url = 'kill-all-sessions'

  let json = {
    secret: "not supported!",
    auth: auth,
    timeout: 120
  }

  let jsonRequest = {
    secret: "...",
    auth: {
      key: "...",
      signature: "...",
      data: "text",
      encoding: auth.encoding
    },
    timeout: 120
  }

  let jsonResponse = {
    base: url
  }

  return (
    <div className="overflow-hidden content-section" id="content-kill-all-sessions">
      <h2>kill all sessions</h2>
      <pre>
        <Code api_ = {api_}
              url = {url}
              jsonRequestLabel = "JSON Request"
              jsonResponseLabel = "JSON Response"
              jsonRequest = {jsonRequest}
              jsonResponse = {jsonResponse}
              commentForResponse = ""
        />

        <button
          key={url}
          className="api-btn"
          type="button"
          disabled={isLoader || session.length <= 0}
          onClick={(event) => onPost(event, url, json)}
        >
           Kill All Sessions
        </button>
      </pre>
      <p>
        Kill all user processes.<br/>
        You need to make a POST call to the following url:<br/>
        <i>/{url}</i>
      </p>
      <br/>
      <h4>QUERY PARAMETERS</h4>
      <table className="central-overflow-x">
        <thead>
        <tr>
          <th>Field</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>secret*</td>
          <td>String</td>
          <td>.</td>
        </tr>
        <tr>
          <td>auth*</td>
          <td>Object</td>
          <td>Authorization</td>
        </tr>
        <tr>
          <td>timeout*</td>
          <td>Number</td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <table className="sub-table">
        <thead></thead>
        <tbody>
        <tr>
          <td>* - required</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default KillAllSessions;
