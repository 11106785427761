import React from 'react'
import './error.css'

const Error = ( ) => {

  return (
    <div className="overflow-hidden content-section" id="content-errors">
      <h2>Errors</h2>
      <p>
        The Ursus Online API uses the following error codes:
      </p>
      <table>
        <thead>
        <tr>
          <th>Code</th>
          <th>Meaning</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>400</td>
          <td>
            <code className="higlighted">Bad Request</code>. Some parameters are missing. This error appears when you don't pass every mandatory parameters.
          </td>
        </tr>
        <tr>
          <td>401</td>
          <td>
            <code className="higlighted">Unauthorized</code>.
          </td>
        </tr>
        <tr>
          <td>403</td>
          <td>
            <code className="higlighted">Forbidden</code>.
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Error;
