import React from 'react'
import './get-started.css'

const GetStarted = ({api_}) => {

  return (
    <div className="overflow-hidden content-section" id="content-get-started">
      <h1>Get started</h1>
      <pre>
        API Endpoint: <span className="code-example">{api_}</span>
      </pre>
      <p>
        The Ursus API provides programmatic access to read Ursus online data.
        To use this API, you need an <strong>Public Key & Signature</strong>.
        {/*
        Please contact us at <a href="mailto:api@ursus-online.com">api@ursus-online.com</a>.
        */}
      </p>
    </div>
  );
};

export default GetStarted;
