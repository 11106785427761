export function contract(meta) {
  let contractCustomTypes = []
  let contractName = '?'
  let externalPublicFunctions = []
  let contractMethods = []

  for (let key in meta) {
    if (key === 'ContractBasic') {
      //console.log(isObject(meta[key]))
      for (let key_ in meta[key]) {
        if (key_ === 'ContractName') {
          //console.log(meta[key][key_]) //ContractName
          contractName = meta[key][key_]
        }
      }
    }
    if (key === 'ContractCustomTypes') {
      contractCustomTypes = meta[key]
    }
    if (key === 'ExternalPublicFunctions') {
      for (let key_ in meta[key]) {
        externalPublicFunctions.push(key_)
      }
    }
    if (key === 'ContractMethods') {
      for (let key_ in meta[key]) {
        contractMethods.push(key_)
      }
    }
  }
  return {contractName, contractCustomTypes, externalPublicFunctions, contractMethods}
}
