// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fileUploadWrapper {
  position: relative;
  display: inline-block;
}

.fileUploadInput {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.fileUploadButton,
.fileUploadButtonOk {
  padding: 6px 20px;
  height: 40px;
  background-color: white;
  border: 1px solid #2D2D2D;
  color: black;
  font-size: 22px;
  border-radius: 12px;
  white-space: nowrap;
  font-family: 'Alumni Sans Pinstripe', cursive;
  margin-right: 5px;
}

.fileUploadButtonOk {
  background-color: lightblue;
}
/*
.fileUploadButton:hover {
  background-color: #5881C0;
}
*/
`, "",{"version":3,"sources":["webpack://./src/components/translate/translate.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,UAAU;EACV,eAAe;AACjB;;AAEA;;EAEE,iBAAiB;EACjB,YAAY;EACZ,uBAAuB;EACvB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,mBAAmB;EACnB,6CAA6C;EAC7C,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;AAC7B;AACA;;;;CAIC","sourcesContent":[".fileUploadWrapper {\n  position: relative;\n  display: inline-block;\n}\n\n.fileUploadInput {\n  position: absolute;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  width: 100%;\n  height: 100%;\n  opacity: 0;\n  cursor: pointer;\n}\n\n.fileUploadButton,\n.fileUploadButtonOk {\n  padding: 6px 20px;\n  height: 40px;\n  background-color: white;\n  border: 1px solid #2D2D2D;\n  color: black;\n  font-size: 22px;\n  border-radius: 12px;\n  white-space: nowrap;\n  font-family: 'Alumni Sans Pinstripe', cursive;\n  margin-right: 5px;\n}\n\n.fileUploadButtonOk {\n  background-color: lightblue;\n}\n/*\n.fileUploadButton:hover {\n  background-color: #5881C0;\n}\n*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
