import React, {useEffect, useState} from 'react'
import './authorize-session.css'
import Code from '../code'

const AuthorizeSession = ({api, api_, auth, session, onPost, isLoader}) => {
  //const [curSession, setCurSession] = useState(session)
  let url = 'authorize-session'

  let json = {
    auth: auth,
    timeout: 10
  }

  let jsonRequest = {
    auth: {
      key: "...",
      signature: "...",
      data: "text",
      encoding: auth.encoding
    },
    timeout: 10
  }

  let jsonResponse = {
    base: url,
    session: "...",
    status: "success",
    files: [{
      path: "/share/...",
      event: "authorize-session",
      type: "file",
      body: ""
    }],
    meta: {}
  }

  /*
  let json = {
    secret: secret,
    session_id: curSession,
    timeout: 120,
  }
  */

  /*
  useEffect(() => {
    if (curSession !== session) {
      setCurSession(session)
    }
    return () => {
      // Component unmount code.
    };
  }, [session])
  */

  /*
  const isUUID = ( uuid ) => {
    let result = false
    let s = "" + uuid
    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[4][0-9a-fA-F]{3}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$')
    if (s !== null) {
      result = true
    }
    return result
  }
  */

  return (
    <div className="overflow-hidden content-section" id="content-authorize-session">
      <h2>authorize session</h2>
      <pre>
        <Code api_ = {api_}
              url = {url}
              jsonRequestLabel = "JSON Request"
              jsonResponseLabel = "JSON Response"
              jsonRequest = {jsonRequest}
              jsonResponse = {jsonResponse}
              commentForResponse = ""
        />

        <div className="session-form">
          {/*
          <input type="text"
                 id="session"
                 className="session-input"
                 value={curSession}
                 pattern="[A-Za-z0-9\-]{36}" required maxLength="36"
                 onChange={e => setCurSession(e.target.value)}/>
          */}
          <button
            key={url}
            className="api-btn"
            type="button"
            //disabled={isLoader || !isUUID(curSession)}
            //disabled={isLoader || curSession.length === 0}
            disabled={isLoader || session.length > 0}
            onClick={(event) => onPost(event, url, json)}
          >
             Authorize Session
          </button>
          {session && session.length > 0 && (
            <div className="check"> </div>
          )}
        </div>
      </pre>
      <p>Retrieve session.<br/>
        You need to make a POST call to the following url:<br/>
        <i>/{url}</i>
      </p>
      <br/>
      <h4>QUERY PARAMETERS</h4>
      <table className="central-overflow-x">
        <thead>
        <tr>
          <th>Field</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>auth*</td>
          <td>Object</td>
          <td>Authorization</td>
        </tr>
        <tr>
          <td>timeout</td>
          <td>Number</td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <table className="sub-table">
        <thead></thead>
        <tbody>
        <tr>
          <td>* - required</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AuthorizeSession;
