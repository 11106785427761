import React, {useEffect, useState} from 'react'
import './notifications.css'
import clear from "../../images/clear.svg"

const Notifications = ({ notifications, notification }) => {

  const [visibleNotifications, setVisibleNotifications] = useState([])
  const [inc, setInc] = useState(0)

  const [logs, setLogs] = useState(false)
  const notificationBell = document.getElementsByClassName('notification-bell')
  const bellRad = document.getElementsByClassName('bell-rad')
  const notificationCount = document.getElementsByClassName('notification-count')

  const timing = {
    duration: 300,
    iterations: 1,
    fill: 'forwards',
  }
  const bell = [
    { transform: "rotate(0)" },
    { transform: "rotate(30deg)" },
    { transform: "rotate(0)" },
    { transform: "rotate(0)" },
    { transform: "rotate(-30deg)" },
    { transform: "rotate(0)" }
  ]
  const rad = [
    { transform: "translateX(0)"},
    { transform: "translateX(6px)"},
    { transform: "translateX(0)"},
    { transform: "translateX(0)"},
    { transform: "translateX(-6px)"},
    { transform: "translateX(0)"}
  ]
  const zoom = [
    { opacity: "0", transform: "scale(0)"},
    { opacity: "1", transform: "scale(1)"},
    { opacity: "1"}
  ]
  /*
  const tlClear = [
    { opacity: "0", scale: "0", margin: "0", padding: "0", display: "none"}
  ]
  */
  const onBell = () => {
    if (notificationBell.length > 0 && bellRad.length > 0 && notificationCount.length > 0) {
      notificationBell[0].animate(bell, timing)
      bellRad[0].animate(rad, timing)
      notificationCount[0].animate(zoom, timing)
    }
  }

  const onClear = (index) => {
    //e.preventDefault()
    //console.log(index)
    setInc(inc - 1)
    const iTopLeft = document.getElementsByClassName('top-left-'+index)
    if (iTopLeft.length > 0) {
      iTopLeft[0].style.display="none"
      //iTopLeft[0].animate(tlClear, timing)
    }
    /*
    if (iTopLeft.length > 0) {
      iTopLeft[0].parentNode.removeChild(iTopLeft[0]);
    }
    */
  }

  const onLogs = () => {
    setLogs(!logs)
  }

  useEffect(() => {
    if (notification['status']) {
      setInc(inc + 1)
      let v = visibleNotifications
      ////v.unshift(notification)
      v.push(notification)
      setVisibleNotifications(v)
      setTimeout(onBell, 1)
      ////setTimeout(() => onClear(inc+1), 3000)
    }
    //return () => // Component unmount code.
  }, [notification])

  /*
  useEffect(() => {
    if (notification['status']) {
      setInc(inc + 1)
      setTimeout(onBell, 1)
    }
    //return () => // Component unmount code.
  }, [notification])
  */
  return (<>
    <div className="notifications">
      {inc > 0 && (
        <div className="notification-box" onClick={() => onLogs()}>
          <span className="notification-count">{inc}</span>
          <div className="notification-bell">
            <span className="bell-top"> </span>
            <span className="bell-middle"> </span>
            <span className="bell-bottom"> </span>
            <span className="bell-rad"> </span>
          </div>
        </div>
      )}

      <ul>
        {visibleNotifications.map((item, index) => (
          <React.Fragment key={'item-' + index}>
            {item.status && item.message && item.data && (
              <li
                key={'li-'+index}
              >
                <div
                  className={`top-left-${index} top-left ${item.status}`}>
                  {item.message}
                  <button
                    key={'clear-btn-'+index}
                    className="clear-btn" type="button"
                    onClick={() => onClear(index)}
                  >
                    <img className="clear" alt="" src={clear}/>
                  </button>
                </div>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>

      {inc > 0 && logs && (
        <ul className="logs">
          {visibleNotifications.map((item, index) => (
            <React.Fragment key={'item--' + index}>
              {item.status && item.message && item.data && (
                <li
                  key={'li-log-'+index}
                >
                  <div className={`log`}>
                    <div className={`log-${item.status}`}>
                      {item.message}
                    </div>
                    {Object.keys(item.data).map((key) => (
                        <React.Fragment key={'first_log-key-'+key}>
                          {key !== 'meta' && key !== 'files' && (
                            <React.Fragment key={'log-key-'+key}>
                              <div className="log-key">
                                {key}:
                              </div>
                              <div className="log-data">
                          <pre>
                            {item.data[key]}
                          </pre>
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                    ))}
                  </div>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      )}

    </div>
  </>)
}
export default Notifications;
