import React from 'react'
import './create-session.css'
import Code from '../code'

const CreateSession = ({api, api_, auth, session, onPost, isLoader}) => {
  let url = 'create-session'

  let json = {
    auth: auth,
    timeout: 10
  }

  let jsonRequest = {
    auth: {
      key: "...",
      signature: "...",
      data: "text",
      encoding: auth.encoding
    },
    timeout: 10
  }

  let jsonResponse = {
    base: url,
    session: "...",
    status: "success",
    files: [{
      path: "/share/...",
      event: "authorize-session",
      type: "file",
      body: ""
    }]
  }

  /*
  return (
    <button
      key={url+'gosh'}
      className="api-btn top-btn"
      type="button"
      disabled={isLoader}
      onClick={(event) => onPost(event, url, json)}
    >
      Create Session
    </button>
  )
  */

  return (
    <div className="overflow-hidden content-section" id="content-create-session">
      <h2>create-session</h2>
      <pre>
        <Code api_ = {api_}
              url = {url}
              jsonRequestLabel = "JSON Request"
              jsonResponseLabel = "JSON Response"
              jsonRequest = {jsonRequest}
              jsonResponse = {jsonResponse}
              commentForResponse = ""
        />

        <button
          key={url}
          className="api-btn"
          type="button"
          disabled={isLoader || session.length > 0}
          onClick={(event) => onPost(event, url, json)}
        >
           Create Session
        </button>
        {session && session.length > 0 && (
          <div className="check"> </div>
        )}
      </pre>
      <p>Start or retrieve session.<br/>
        You need to make a POST call to the following url:<br/>
        <i>/{url}</i>
      </p>
      <br/>
      <h4>QUERY PARAMETERS</h4>
      <table className="central-overflow-x">
        <thead>
        <tr>
          <th>Field</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>auth*</td>
          <td>Object</td>
          <td>Authorization</td>
        </tr>
        <tr>
          <td>timeout</td>
          <td>Number</td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <table className="sub-table">
        <thead></thead>
        <tbody>
        <tr>
          <td>* - required</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  );

};

export default CreateSession;
