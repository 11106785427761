import React from "react"
import { keyPressAZ09_ } from '../../utils/key-press-az09_'
type AProps = {
  argumentName: string;
  choose: any;
  setArgumentName: any;
  setArgumentType: any;
  isLoader: any;
  deleteArgument: any;
  verificationAddArgument: any;
  isArgumentBasicTypeVerification: any;
  argumentType: string;
  isArgumentCustomTypeVerification: any;
  verificationAddArgumentType: any;
  isVerification: any;
  isArgumentNameVerification: any;
  updateArgument: any;
  addArgument: any;
  isArgumentTypeVerification: any;
  code: any;
  children?: React.ReactNode; // 👈️ added type for children
}

const AddArgument = (props: AProps) => {
  const argumentName = props.argumentName
  const choose = props.choose
  const setArgumentName = props.setArgumentName
  const setArgumentType = props.setArgumentType
  const isLoader = props.isLoader
  const deleteArgument = props.deleteArgument
  const verificationAddArgument = props.verificationAddArgument
  const isArgumentBasicTypeVerification = props.isArgumentBasicTypeVerification
  const argumentType = props.argumentType
  const isArgumentCustomTypeVerification = props.isArgumentCustomTypeVerification
  const verificationAddArgumentType = props.verificationAddArgumentType
  const isVerification = props.isVerification
  const isArgumentNameVerification = props.isArgumentNameVerification
  const updateArgument = props.updateArgument
  const addArgument = props.addArgument
  const isArgumentTypeVerification = props.isArgumentTypeVerification
  const code = props.code

  return <>
    {(code.arguments !== undefined && code.arguments.length > 0) && (
      <React.Fragment>
        {code.arguments}
      </React.Fragment>
    )}
    {(code.addArgument !== undefined) && (
      <React.Fragment>
        {code.addArgument.map((a, i) => (
          <div key={'add-argument-'+i} className="argumentCode_">
            <div className={`argumentCode ${(argumentName === a.name) && ("activeCode")}`}>
              <div className="argumentNameType" onClick={(e) => choose(e, a, setArgumentName, setArgumentType, "argument")}>
                {'('}<div className={`argumentName ${(argumentName === a.name) && ("activeName")}`}>{a.name}</div>{' : '}<div className="argumentType">{a.type}</div>)
              </div>
              <button
                title={`Delete - ${a.name}`}
                key='del-argument'
                className="del-btn"
                type="button"
                disabled={isLoader || !isVerification}
                onClick={(e) => deleteArgument(e, i)}
              >
                <div className="icon del-icon"/>
              </button>
            </div>&nbsp;
          </div>
        ))}
      </React.Fragment>
    )}

    <br/>
    <div className="add-input-select">
      {/* START input_ */}
      <div className="input_">
        <input type="text"
               id="argument-name"
               className="add-p-input"
               value={argumentName}
               pattern="^[A-Za-z0-9_]{1,30}" maxLength="30"
               disabled={isLoader || !isVerification}
               onKeyPress={e => keyPressAZ09_(e)}
               onChange={e => verificationAddArgument(e.target.value)}
        />
        <div className="editor"/>
      </div>
      {/* END input_ + button */}

      {/* START input ArgumentType */}
      {/*  className="add-p-input" */}
      <div className="input_">
        <input type="text"
               id="argument-type"
               className={`add-p-input ${(isArgumentBasicTypeVerification ) && ("add-p-input-basic")} ${(isArgumentCustomTypeVerification ) && ("add-p-input-custom")}`}

               value={argumentType}
               pattern="^[A-Za-z0-9_]{1,20}" maxLength="20"
               disabled={isLoader || !isVerification}
               onKeyPress={e => keyPressAZ09_(e)}
               onChange={e => verificationAddArgumentType(e.target.value)}
        />
        <div className="editor"/>
      </div>
      {/* END input ArgumentType */}

      {/* START select ArgumentType */}
      {/* !!! OLD - SELECT */}
      {/*
      <div className="select_">
        <select id="argument-type" name="select" onChange={changeArgumentType}>
          {argumentTypeOptions}
        </select>
        <div className="editor"/>
      </div>
      */}

      {/* END select ArgumentType */}

      <button
        title="Add"
        key='add-argument'
        className="add-btn"
        type="button"
        disabled={isLoader || !isVerification || !isArgumentNameVerification || argumentName.length === 0}
        onClick={(e) => addArgument(e)}
      >
        <div className="icon add-icon"/>
      </button>

      {isLoader || !isVerification || !isArgumentTypeVerification || argumentName.length === 0
        ?
        ""
        :
        <button
          title={`Update Type for ${argumentName}`}
          key='update-argument'
          className="update-btn"
          type="button"
          disabled={isLoader || !isVerification || !isArgumentTypeVerification || argumentName.length === 0}
          onClick={(e) => updateArgument(e)}
        >
          <div className="icon update-icon"/>
        </button>
      }
      <div className={`add-label ${(isLoader || !isVerification || !isArgumentTypeVerification || argumentName.length === 0) && ("add-label-left24")}`}>Argument</div>
    </div>
    &nbsp;
  </>
}

export default AddArgument
