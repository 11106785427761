import React, {useEffect, useState} from 'react'
import './prove.css'
import { keyPress09 } from '../../utils/key-press-09'
import { isTimeout } from '../../utils/timeout'
import Code from '../code'

const Prove = ({api, api_, auth, session, onPost, isLoader, propositions, curContract}) => {

  const [method, setMethod] = useState('')
  const [proposition, setProposition] = useState('')
  const [isProve, setIsProve] = useState(false)
  const [timeout, setTimeout] = useState(360)

  let url = 'prove'

  let json = {
    auth: auth,
    contract: curContract.contractName,
    function: method,
    proposition:  proposition,
    timeout: timeout,
  }

  let jsonRequest = {
    auth: {
      key: "...",
      signature: "...",
      data: "text",
      encoding: auth.encoding
    },
    contract: curContract.contractName,
    function: method,
    proposition:  proposition,
    timeout: timeout
  }

  let jsonResponse = {
    base: url,
    proposition: proposition
  }

  useEffect(() => {
    findProve(proposition)
    return () => {
      // Component unmount code.
    }
  }, [propositions, proposition])

  const findProve = (value) => {
    let i = propositions.findIndex(item => item.name === value)
    if (i >= 0) {
      setMethod(propositions[i].function)
      if (propositions[i].status === 'success' && propositions[i].proof_status === 'success') {
        setIsProve(true)
      } else {
        setIsProve(false)
      }
    } else {
      setMethod('')
      setIsProve(false)
    }
  }

  const changeProposition = (e) => {
    setProposition(e.target.value)
  }

  const onTimeout = (value) => {
    setTimeout(isTimeout(value))
  }

  return (
    <div className="overflow-hidden content-section" id="content-prove">
      <h2>prove</h2>
      <pre>
        <Code api_ = {api_}
              url = {url}
              jsonRequestLabel = "JSON Request"
              jsonResponseLabel = "JSON Response"
              jsonRequest = {jsonRequest}
              jsonResponse = {jsonResponse}
              commentForResponse = "+ asynchronous socket return"
        />

        {/* START select Proposition */}
        <div className="select_">
          <select name="select" onChange={changeProposition}>
            <option key={'optionP--0'} value="">Select Proposition:</option>
            {Object.keys(propositions).map((key, n) => (
              <React.Fragment key={'optionCP-'+key}>
                {propositions[key].status === 'success' && (
                <option
                  key={'optionP--'+key}
                  value={propositions[key].name}
                >
                  {propositions[key].name}{/*- {propositions[key].proof_status}*/}
                </option>
                )}
              </React.Fragment>
            ))}
          </select>
          <div className="editor"/>
        </div>
        {/* END select Proposition */}

        <div className="input_">
                  <input type="text"
                         id="ce-timeout"
                         className="add-p-input"
                         value={timeout}
                         pattern="^[0-9]{1,4}" required maxLength="4"
                         onKeyPress={e => keyPress09(e)}
                         onChange={e => onTimeout(e.target.value)}
                  />
          <div className="editor"/>
        </div>

        <button
          key={url}
          className="api-btn"
          type="button"
          disabled={isLoader || isProve || proposition.length === 0 || timeout.length === 0 || timeout < 1}
          onClick={(event) => onPost(event, url, json)}
        >
           Prove
        </button>
        {isProve && (
          <div className="check"> </div>
        )}
      </pre>
      <p>
        Try to prove the given proposition.<br/>
        You need to make a POST call to the following url:<br/>
        <i>/{url}</i>
      </p>
      <br/>
      <h4>QUERY PARAMETERS</h4>
      <table className="central-overflow-x">
        <thead>
        <tr>
          <th>Field</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>auth*</td>
          <td>Object</td>
          <td>Authorization</td>
        </tr>
        <tr>
          <td>function*</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>proposition*</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>timeout*</td>
          <td>Number</td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <table className="sub-table">
        <thead></thead>
        <tbody>
        <tr>
          <td>* - required</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Prove;
