import React from "react"
import { keyPressAZ09_ } from '../../utils/key-press-az09_'
import {keyPressAZ09___} from "../../utils/key-press-az09___";
type AProps = {
  setName: string;
  choose: any;
  setSetName: any;
  setSetType: any;
  isLoader: any;
  deleteSet: any;
  verificationAddSet: any;
  setType: string;
  verificationAddSetType: any;
  isVerification: any;
  isSetNameVerification: any;
  updateSet: any;
  addSet: any;
  isSetTypeVerification: any;
  code: any;
  children?: React.ReactNode; // 👈️ added type for children
}

const AddSet = (props: AProps) => {
  const setName = props.setName
  const choose = props.choose
  const setSetName = props.setSetName
  const setSetType = props.setSetType
  const isLoader = props.isLoader
  const deleteSet = props.deleteSet
  const verificationAddSet = props.verificationAddSet
  const setType = props.setType
  const verificationAddSetType = props.verificationAddSetType
  const isVerification = props.isVerification
  const isSetNameVerification = props.isSetNameVerification
  const updateSet = props.updateSet
  const addSet = props.addSet
  const isSetTypeVerification = props.isSetTypeVerification
  const code = props.code

  return <>
    {(code.sets !== undefined && code.sets.length > 0) && (
      <React.Fragment>
        {code.sets}
      </React.Fragment>
    )}
    {(code.addSet !== undefined) && (
      <React.Fragment>
        {code.addSet.map((a, i) => (
          <div key={'add-set-'+i} className="setCode_">
            {'\n'}
            <div className={`setCode ${(setName === a.name) && ("activeCode")}`}>
              <div className="setNameType" onClick={(e) => choose(e, a, setSetName, setSetType, "set")}>
                {'set ('}<div className={`setName ${(setName === a.name) && ("activeName")}`}>{a.name}</div>{' := '}<div className="setType">{a.type}</div>).
              </div>
              <button
                title={`Delete - ${a.name}`}
                key='del-set'
                className="del-btn"
                type="button"
                disabled={isLoader || !isVerification}
                onClick={(e) => deleteSet(e, i)}
              >
                <div className="icon del-icon"/>
              </button>
            </div>&nbsp;
          </div>
        ))}
      </React.Fragment>
    )}

    <br/>
    <div className="add-input-select">
      {/* START input_ */}
      <div className="input_">
        <input type="text"
               id="set-name"
               className="add-p-input"
               value={setName}
               pattern="^[A-Za-z0-9_]{1,30}" maxLength="30"
               disabled={isLoader || !isVerification}
               onKeyPress={e => keyPressAZ09_(e)}
               onChange={e => verificationAddSet(e.target.value)}
        />
        <div className="editor"/>
      </div>
      {/* END input_ + button */}

      {/* START input SetType */}
      {/*  className="add-p-input" */}
      <div className="input_ input__">
        <br />
        <textarea rows="2" cols="45"
                  id="set-type"
                  className="add-p-input"
                  value={setType}
                  //pattern="^[A-Za-z0-9_]{1,20}" maxLength="20"
                  //pattern="^[A-Za-z0-9_\/\<>=\(\)' ]{0,500}" maxLength="500"
                  maxLength="500"
                  disabled={isLoader || !isVerification}
                  //onKeyPress={e => keyPressAZ09_(e)}
                  //onKeyPress={e => keyPressAZ09___(e)}
                  onChange={e => verificationAddSetType(e.target.value)}
        >
        </textarea>
        {/*
        <div className="editor"/>
        */}
      </div>
      {/* END input SetType */}

      <button
        title="Add"
        key='add-set'
        className="add-btn"
        type="button"
        disabled={isLoader || !isVerification || !isSetNameVerification || setName.length === 0}
        onClick={(e) => addSet(e)}
      >
        <div className="icon add-icon"/>
      </button>

      {isLoader || !isVerification || !isSetTypeVerification || setName.length === 0
        ?
        ""
        :
        <button
          title={`Update Type for ${setName}`}
          key='update-set'
          className="update-btn"
          type="button"
          disabled={isLoader || !isVerification || !isSetTypeVerification || setName.length === 0}
          onClick={(e) => updateSet(e)}
        >
          <div className="icon update-icon"/>
        </button>
      }
      <div className={`add-label ${(isLoader || !isVerification || !isSetTypeVerification || setName.length === 0) && ("add-label-left24")}`}>Set</div>
    </div>
    &nbsp;
  </>
}

export default AddSet
