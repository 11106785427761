import React, {useEffect, useState} from 'react'
import './auth.css'
import workflow from '../../images/workflow.png'

const Auth = ({api, auth, session}) => {

  let url = 'auth'

  return (
    <div className="overflow-hidden content-section" id="content-auth">
      <h2>auth</h2>
      <pre>
        <code className="json hljs">
          <div>
            <img alt="" title="" src={workflow} width="100%"/>
          </div>
        </code>
      </pre>
      <p>
        Authorization.<br/>
        You can to make a GET call to the following url:<br/>
        <i>{window.location.origin}/?key=value&signature=value&data=value</i>
      </p>
      <br/>
      <h4>QUERY PARAMETERS</h4>
      <table className="central-overflow-x">
        <thead>
        <tr>
          <th>Field</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>key*</td>
          <td>Base64/Hex</td>
          <td>Public Key</td>
        </tr>
        <tr>
          <td>signature*</td>
          <td>Base64/Hex</td>
          <td></td>
        </tr>
        <tr>
          <td>data*</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>encoding</td>
          <td>String</td>
          <td>base64/hex Default base64</td>
        </tr>
        </tbody>
      </table>

      <table className="sub-table">
        <thead></thead>
        <tbody>
        <tr>
          <td>* - required</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Auth;
