import React, {useEffect, useState} from 'react'
import './compile-translated.css'
import { keyPress09 } from '../../utils/key-press-09'
import { isTimeout } from '../../utils/timeout'
import Code from "../code";

const CompileTranslated = ({api, api_, auth, session, onPost, isLoader, files}) => {
  const [timeout, setTimeout] = useState(120)
  const [isCompileTranslated, setIsCompileTranslated] = useState(false)

  let url = 'compile-translated'

  let json = {
    auth: auth,
    timeout: timeout
  }

  let jsonRequest = {
    auth: {
      key: "...",
      signature: "...",
      data: "text",
      encoding: auth.encoding
    },
    timeout: timeout
  }

  let jsonResponse = {
    base: url
  }

  const onTimeout = (value) => {
    setTimeout(isTimeout(value))
  }

  useEffect(() => {
    findCompileTranslated()
    return () => {
      // Component unmount code.
    }
  }, [files])

  /*
  useEffect(() => {
    console.log(isCompileTranslated)
    return () => {
      // Component unmount code.
    }
  }, [isCompileTranslated])
  */

  const findCompileTranslated = () => {
    const meta = '/share/'+session+'/artifacts/meta.json'
    if (files.findLastIndex(item => item.path === meta && item.event !== 'delete') >= 0) {
      setIsCompileTranslated(true)
    } else {
      setIsCompileTranslated(false)
    }
  }

  return (
    <div className="overflow-hidden content-section" id="content-compile-translated">
      <h2>compile-translated</h2>
      <pre>
        <Code api_ = {api_}
              url = {url}
              jsonRequestLabel = "JSON Request"
              jsonResponseLabel = "JSON Response"
              jsonRequest = {jsonRequest}
              jsonResponse = {jsonResponse}
              commentForResponse = "+ asynchronous socket return"
        />

        <div className="input_">
          <input type="text"
                 id="ce-timeout"
                 className="add-p-input"
                 value={timeout}
                 pattern="^[0-9]{1,4}" required maxLength="4"
                 onKeyPress={e => keyPress09(e)}
                 onChange={e => onTimeout(e.target.value)}
          />
          <div className="editor"/>
        </div>

        <button
          key={url}
          className="api-btn"
          type="button"
          disabled={isLoader || isCompileTranslated || session.length <= 0 || timeout.length === 0 || timeout < 1}
          onClick={(event) => onPost(event, url, json)}
        >
           Compile Translated
        </button>
        {isCompileTranslated && (
          <div className="check"> </div>
        )}
      </pre>
      <p>
        Compile translated Ursus file.<br/>
        You need to make a POST call to the following url:<br/>
        <i>/{url}</i>
      </p>
      <br/>
      <h4>QUERY PARAMETERS</h4>
      <table className="central-overflow-x">
        <thead>
        <tr>
          <th>Field</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>auth*</td>
          <td>Object</td>
          <td>Authorization</td>
        </tr>
        <tr>
          <td>timeout*</td>
          <td>Number</td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <table className="sub-table">
        <thead></thead>
        <tbody>
        <tr>
          <td>* - required</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CompileTranslated;
