import React, {useEffect, useState} from 'react'
import axios from 'axios'
import './api.css'
import logo from '../../logo.png'

import GetStarted from "../get-started"
import Auth from "../auth"
import CreateSession from "../create-session"
import AuthorizeSession from "../authorize-session"
import CompileTranslated from "../compile-translated"
import GetUrsusMeta from "../get-ursus-meta"
import CreateExec from "../create-exec"
import AddProposition from "../add-proposition"
import CompileProp from "../compile-prop"
import KillAllProofs from "../kill-all-proofs"
import KillAllSessions from "../kill-all-sessions"
import Prove from "../prove"
import Translate from "../translate"
import Error from "../error"

const Api = ({ api, api_, auth, runAuth, sol, session, setSession, updateNotifications, meta, files, execs, propositions, curContract,
               startCreateExec,
               startTranslate,
               startCompileTranslated,
               startCompileProp,
               startProve
            }) => {

  useEffect(() => {
    (async () => {
      let elements = [];

      [].forEach.call(document.querySelectorAll('.scroll-to-link'), function (div) {
        div.onclick = function (e) {
          e.preventDefault();
          let target = this.dataset.target;
          document.getElementById(target).scrollIntoView({ behavior: 'smooth' });
          let elems = document.querySelectorAll(".content-menu ul li");
          [].forEach.call(elems, function (el) {
            el.classList.remove("active");
          });
          this.classList.add("active");
          return false;
        };
      })

      /*
      document.getElementById('button-menu-mobile').onclick = function (e) {
        e.preventDefault();
        document.querySelector('html').classList.toggle('menu-opened');
      }

      document.querySelector('.left-menu .mobile-menu-closer').onclick = function (e) {
        e.preventDefault();
        document.querySelector('html').classList.remove('menu-opened');
      }
      */

      const debounce = (func) => {
        let timer;
        return function (event) {
          if (timer) clearTimeout(timer);
          timer = setTimeout(func, 100, event);
        };
      }

      const calculateElements = () => {
        let totalHeight = 0;
        elements = [];
        [].forEach.call(document.querySelectorAll('.content-section'), function (div) {
          let section = {};
          section.id = div.id;
          totalHeight += div.offsetHeight;
          section.maxHeight = totalHeight - 25;
          elements.push(section);
        });
        onScroll();
      }

      const onScroll = () => {
        let scroll = window.pageYOffset;
        //console.log('scroll', scroll, elements)
        for (let i = 0; i < elements.length; i++) {
          let section = elements[i];
          if (scroll <= section.maxHeight) {
            let elems = document.querySelectorAll(".content-menu ul li");
            [].forEach.call(elems, function (el) {
              el.classList.remove("active");
            });
            let activeElems = document.querySelectorAll(".content-menu ul li[data-target='" + section.id + "']");
            [].forEach.call(activeElems, function (el) {
              el.classList.add("active");
            });
            break;
          }
        }
        if (window.innerHeight + scroll + 5 >= document.body.scrollHeight) { // end of scroll, last element
          let elems = document.querySelectorAll(".content-menu ul li");
          [].forEach.call(elems, function (el) {
            el.classList.remove("active");
          });
          let activeElems = document.querySelectorAll(".content-menu ul li:last-child");
          [].forEach.call(activeElems, function (el) {
            el.classList.add("active");
          });
        }
      }

      calculateElements();
      window.onload = () => {
        calculateElements();
      };
      /*
      window.addEventListener("resize", debounce(function (e) {
        e.preventDefault();
        calculateElements();
      }));
      window.addEventListener('scroll', function (e) {
        e.preventDefault();
        onScroll();
      });
      */
    })();
    return () => {
      // Component unmount code.
    };
  }, [])

  useEffect(() => {
    if (auth.key && auth.signature && auth.data) {
      let url = 'create-session' // -> 'authorize-session'
      let jsonAuth = {
        auth: auth
      }

      if (runAuth !== null) {
        const event = document.createEvent("HTMLEvents");
        onPost(event, url, jsonAuth).then(r => {
          //console.log('Ok')
        })
        /*
        //Variation via XMLHttpRequest
        //Для этого варианта надо в обработу xhr.readyState == 4 && xhr.status == 200
        //Включть блок что у axios в .then(

        const xhr = new XMLHttpRequest()
        xhr.open('POST', `${api}${url}/`)
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        xhr.setRequestHeader('Authorization','Bearer '+session);
        xhr.onreadystatechange = function ()
        {
          if (xhr.readyState == 4 && xhr.status == 200)
            console.log("Ok")
        }
        xhr.send(JSON.stringify(jsonAuth))
        */
      }
    }

    return () => {
      // Component unmount code.
    };
  }, [auth])

  //////////////////////////////////////////////////////////
  const [isLoader, setIsLoader] = useState(false);

  const onPost = async (e, url, json) => {
    e.preventDefault()
    setIsLoader(true)
    let result = ''
    try {
      /*
      //!!! auth for test
      //!!! Specially closed !!!
      await axios.post(`${api}auth/`, JSON.stringify({}), {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
        }
      })
        .then((response) => {
          console.log(response.data)
        })
      */
      ///

      await axios.post(`${api}${url}/`, JSON.stringify(json), {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+session
        }
      })
        .then((response) => {
          let status = 'success'
          let additionalMessage = ''
          result = response.data
          if (result['status']) {
            status = result['status']
            additionalMessage = ' - success'
          }
          if (
              result['base'] === 'translate' ||
              result['base'] === 'compile-translated' ||
              result['base'] === 'create-exec' ||
              //result['base'] === 'add-proposition' ||
              result['base'] === 'compile-prop' ||
              result['base'] === 'prove'
          ) {
            status = 'warning'
            additionalMessage = ' - start'
          }
          if (result['function']) {
            status = 'warning'
            additionalMessage = ' for ' + result['function'] + ' - start'
          }
          if (result['proposition'] && result['base'] !== 'add-proposition') {
            status = 'warning'
            additionalMessage = ' for ' + result['proposition'] + ' - start'
          }
          ///!!!
          if (result['proposition'] && result['base'] === 'add-proposition') {
            status = 'success'
            additionalMessage = ' for ' + result['proposition'] + ' - success'
          }
          if (result['base']) {
            updateNotifications({status, message: result['base'] + additionalMessage, data: result}, true)
          }
          try {
            for (let key in result) {
              if (key === 'session') {
                setSession(result[key])
              }
            }
          } catch (err) {
            //
          }
        })
    }
    catch (error) {
      //console.error(error)
      if (error.response.data) {
        // Current session does not match
        updateNotifications({status: 'error', message: error.response.data, data: {}}, true)
      }
      result = error
    }
    finally {
      setIsLoader(false)
    }
  }

  useEffect(() => {
    if (startCreateExec ||
        startTranslate ||
        startCompileTranslated ||
        startCompileProp ||
        startProve
    ) {
      setIsLoader(true)
    } else {
      setIsLoader(false)
    }
    return () => {
      // Component unmount code.
    }
  }, [startCreateExec, startTranslate, startCompileTranslated, startCompileProp, startProve])

  return (
    <header id="api">

      <div className="left-menu">
        <div className="content-logo">
          <div className="logo">
            <img alt="Ursus Online API" title="Ursus Online API" src={logo} height="64"/>
            <span>Ursus Online API</span>
          </div>
          <button className="burger-menu-icon" id="button-menu-mobile">
            <svg width="34" height="34" viewBox="0 0 100 100">
              <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"> </path>
              <path className="line line2" d="M 20,50 H 80"> </path>
              <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"> </path>
            </svg>
          </button>
        </div>

        <div className="mobile-menu-closer"> </div>

        <div className="content-menu">
          <div className="content-infos">
            <div className="info"><b>Version:</b> 0.9.1</div>
            <div className="info"><b>Last Updated:</b> 26th July, 2024</div> {/* 1st, 2nd, 3rd, 4-31th */}
          </div>
          <ul>
            <li className="scroll-to-link active" data-target="content-get-started">
              <a>GET STARTED</a>
            </li>
            <li className="scroll-to-link" data-target="content-auth">
              <a>Auth</a>
            </li>
            <li className="scroll-to-link" data-target="content-create-session">
              <a>Create Session</a>
            </li>
            <li className="scroll-to-link" data-target="content-authorize-session">
              <a>Authorize Session</a>
            </li>
            <li className="scroll-to-link" data-target="content-translate">
              <a>Translate</a>
            </li>
            <li className="scroll-to-link" data-target="content-compile-translated">
              <a>Compile Translated</a>
            </li>
            <li className="scroll-to-link" data-target="content-get-ursus-meta">
              <a>Get Ursus Meta</a>
            </li>
            <li className="scroll-to-link" data-target="content-create-exec">
              <a>Create Exec</a>
            </li>
            <li className="scroll-to-link" data-target="content-add-proposition">
              <a>Add Proposition</a>
            </li>
            <li className="scroll-to-link" data-target="content-compile-prop">
              <a>Compile Prop</a>
            </li>
            <li className="scroll-to-link" data-target="content-kill-all-proofs">
              <a>Kill All Proofs</a>
            </li>
            <li className="scroll-to-link" data-target="content-kill-all-sessions">
              <a>Kill All Sessions</a>
            </li>
            <li className="scroll-to-link" data-target="content-prove">
              <a>Prove</a>
            </li>
            <li className="scroll-to-link" data-target="content-errors">
              <a>Errors</a>
            </li>
          </ul>
        </div>

      </div>
      <div className="content-page">
        <div className="content-code"></div>
        <div className="content">
          <GetStarted api_ = {api_} />
          <Auth api = {api} auth = {auth} session = {session}/>
          <CreateSession api = {api} api_ = {api_} auth = {auth} session = {session} onPost = {onPost} isLoader = {isLoader} />
          <AuthorizeSession api = {api} api_ = {api_} auth = {auth} session = {session} onPost = {onPost} isLoader = {isLoader}/>
          <Translate api = {api} api_ = {api_} auth = {auth} sol = {sol} session = {session} onPost = {onPost} isLoader = {isLoader}/>
          <CompileTranslated api = {api} api_ = {api_} auth = {auth} session = {session} onPost = {onPost} isLoader = {isLoader} files = {files}/>
          <GetUrsusMeta api = {api} api_ = {api_} auth = {auth} session = {session} onPost = {onPost} isLoader = {isLoader} meta = {meta}/>
          <CreateExec api = {api} api_ = {api_} auth = {auth} session = {session} onPost = {onPost} isLoader = {isLoader} execs = {execs} curContract = {curContract}/>
          <AddProposition auth = {auth} session = {session} onPost = {onPost} isLoader = {isLoader} meta = {meta} execs = {execs} curContract = {curContract}/>
          <CompileProp api = {api} api_ = {api_} auth = {auth} session = {session} onPost = {onPost} isLoader = {isLoader} files = {files} propositions = {propositions} curContract = {curContract}/>
          <KillAllProofs api = {api} api_ = {api_} auth = {auth} session = {session} onPost = {onPost} isLoader = {isLoader}/>
          <KillAllSessions api = {api} api_ = {api_} auth = {auth} session = {session} onPost = {onPost} isLoader = {isLoader}/>
          <Prove api = {api} api_ = {api_} auth = {auth} session = {session} onPost = {onPost} isLoader = {isLoader} propositions = {propositions} curContract = {curContract}/>
          <Error />
          {/*
          START-TEST files
          <ul className="ul-files">
            {Object.keys(files).map((key, ikey) => (
              <li key={'li-file-' + ikey}>
                - {ikey} )
                - {files[ikey].event} ,
                - {files[ikey].type} ,
                - {files[ikey].path}
              </li>
            ))}
          </ul>
          END-TEST files

          <br />
          START-TEST execs
          <ul className="ul-execs">
            {Object.keys(execs).map((key, ikey) => (
              <li key={'li-exec-' + ikey}>
                - {ikey} )
                - {execs[ikey].method} ,
                - {execs[ikey].status}
              </li>
            ))}
          </ul>
          END-TEST execs
          */}
        </div>
        <div className="content-code"></div>
      </div>
    </header>
  );
};

export default Api;
