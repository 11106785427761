import React from "react"
import { keyPressAZ09___ } from '../../utils/key-press-az09___'

type CProps = {
  conditionName: string;
  choose: any;
  verificationAddCondition: any;
  isLoader: any;
  isVerification: any;
  isConditionNameVerification: any;
  addCondition: any;
  code: any;
  setConditionName: any;
  deleteCondition: any;
  children?: React.ReactNode; // 👈️ added type for children
}

const AddCondition = (props: CProps) => {
  const conditionName = props.conditionName
  const choose = props.choose
  const verificationAddCondition = props.verificationAddCondition
  const isLoader = props.isLoader
  const isVerification = props.isVerification
  const isConditionNameVerification = props.isConditionNameVerification
  const addCondition = props.addCondition
  const code = props.code
  const setConditionName = props.setConditionName
  const deleteCondition = props.deleteCondition

  return <>
    {(code.addCondition !== undefined) && (
      <React.Fragment>
        {code.addCondition.map((a, i) => (
          <div key={'add-condition-'+i} className="conditionCode_">
            {'\n'}
            <div className={`conditionCode ${(conditionName === a.name) && ("activeCode")}`}>
              <div className="conditionNameType" onClick={(e) => choose(e, a, setConditionName, undefined, "condition")}>
                сondition(
                <div className={`conditionName ${(conditionName === a.name) && ("activeName")}`}>{a.name}</div>).
              </div>
              <button
                title={`Delete - ${a.name}`}
                key='del-condition'
                className="del-btn"
                type="button"
                disabled={isLoader || !isVerification}
                onClick={(e) => deleteCondition(e, i)}
              >
                <div className="icon del-icon"/>
              </button>
            </div>
          </div>
        ))}
      </React.Fragment>
    )}

    <br/>
    <div className="add-input-select">
      <div className="input_">
        <textarea rows="2" cols="45"
                  id="condition-name"
                  className="add-p-input"
                  value={conditionName}
                  pattern="^[A-Za-z0-9_\/\<>=\(\)' ]{0,500}" maxLength="500"
                  disabled={isLoader || !isVerification}
                  onKeyPress={e => keyPressAZ09___(e)}
                  onChange={e => verificationAddCondition(e.target.value)}
        >
        </textarea>
        {/*
        <div className="editor"/>
        */}
      </div>

      <button
        title="Add"
        key='add-condition'
        className="add-btn"
        type="button"
        disabled={isLoader || !isVerification || !isConditionNameVerification || conditionName.length  === 0}
        onClick={(e) => addCondition(e)}
      >
        <div className="icon add-icon"/>
      </button>

      <div className="add-label add-label-left24">Condition</div>
    </div>
  </>
}

export default AddCondition
