import React, {useEffect, useState, useRef} from 'react'
import './add-proposition.css'
import { isObject } from '../../utils/object'

//import { contract } from '../../utils/contract'

import { keyPressAZ09_ } from '../../utils/key-press-az09_'
import { keyPress09 } from '../../utils/key-press-09'
import { isTimeout } from '../../utils/timeout'

import AddArgument from './add-argument'
import AddComputation from './add-computation'
import AddSet from './add-set'
import AddCondition from './add-condition'
import AddConclusion from './add-conclusion'

//import clear from "../../images/clear.svg"
//import pen from "../../images/pen.svg"

/*
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  },[value]);
  return ref.current;
}
*/

const AddProposition = ({auth, session, onPost, isLoader, meta, execs, curContract}) => {

  const [method, setMethod] = useState('')
  const [lemmaName, setLemmaName] = useState('root_name')
  //const prevLemmaName = usePrevious(lemmaName)
  const [code, setCode] = useState( {
    arguments: '', addArgument: [], computation: '', addComputation: [], sets: '', addSet: [], addCondition: [], addConclusion: []})
  const [isVerification, setIsVerification] = useState(false)

  const [argumentName, setArgumentName] = useState('name')
  const [argumentType, setArgumentType] = useState('address')
  const [isArgumentNameVerification, setIsArgumentNameVerification] = useState(false)
  const [isArgumentTypeVerification, setIsArgumentTypeVerification] = useState(false)

  const [isArgumentBasicTypeVerification, setIsArgumentBasicTypeVerification] = useState(false)
  const [isArgumentCustomTypeVerification, setIsArgumentCustomTypeVerification] = useState(false)

  const [computationName, setComputationName] = useState('name')
  const [computationType, setComputationType] = useState('l')
  const [isComputationTypeVerification, setIsComputationTypeVerification] = useState(false)
  const [isComputationNameTypeVerification, setIsComputationNameTypeVerification] = useState(false)

  const [setName, setSetName] = useState('name')
  const [setType, setSetType] = useState('code')
  const [isSetNameVerification, setIsSetNameVerification] = useState(false)
  const [isSetTypeVerification, setIsSetTypeVerification] = useState(false)

  const [conditionName, setConditionName] = useState('')
  const [isConditionNameVerification, setIsConditionNameVerification] = useState(false)

  const [conclusionName, setConclusionName] = useState('expression')
  const [isConclusionNameVerification, setIsConclusionNameVerification] = useState(false)

  const [currentTab, setCurrentTab] = useState('1')
  const [isExec, setIsExec] = useState(false)

  const [textCode, setTextCode] = useState('')

  const [timeout, setTimeout] = useState(10)
  const [timeoutCE, setTimeoutCE] = useState(240)

  let url = 'add-proposition'
  let createExecUrl = 'create-exec'

  const tabs = [
    {
      id: 1,
      title: 'Editor'
    },
    {
      id: 2,
      title: 'Code'
    },
    {
      id: 3,
      title: 'JSON'
    }
  ];

  let json = {
    auth: auth,
    contract: curContract.contractName,
    function: method,
    name: method+'_'+lemmaName+'_def',
    code: textCode,
    timeout: timeout
  }

  let jsonResponse = {
    base: url,
    proposition: method+'_'+lemmaName+'_def',
    files: [{
      path: "/share/...",
      event: url,
      type: "file",
      body: ""
    }]
  }

  let createExecJson = {
    auth: auth,
    contract: curContract.contractName,
    function: method,
    timeout:timeoutCE
  }

  const onTimeout = (value, forCE = false) => {
    const t = isTimeout(value)
    if (forCE) {
      setTimeoutCE(t)
    } else {
      setTimeout(t)
    }
  }

  /*
  const onInit = () => {
  }
  const onDestroy = () => {
  }
  */
  React.useEffect(() => {
    const selectAll = document.querySelectorAll('.select_ select')
    const inputAll = document.querySelectorAll('.input_ input')

    function getTextWidth(el) {
      const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"))
      const context = canvas.getContext("2d")
      const font = window.getComputedStyle(el, null).getPropertyValue('font')
      const text = el.value
      context.font = font
      const textMeasurement = context.measureText(text)
      return textMeasurement.width
    }
    function resizeInput() {
      const width = Math.floor(getTextWidth(this))
      const widthInPx = (width + 20) + "px"
      this.style.width = widthInPx
    }

    selectAll.forEach((select) => {
      select.addEventListener('change', (event) => {
        let tempSelect = document.createElement('select'),
            tempOption = document.createElement('option')

        tempOption.textContent = event.target.options[event.target.selectedIndex].text
        tempSelect.style.cssText += `visibility: hidden; position: fixed;`
        tempSelect.appendChild(tempOption)
        event.target.after(tempSelect)

        const tempSelectWidth = tempSelect.getBoundingClientRect().width
        event.target.style.width = `${tempSelectWidth}px`
        tempSelect.remove()
      })
      select.dispatchEvent(new Event('change'))
    })

    inputAll.forEach((input) => {
      input.addEventListener('input', resizeInput)
      resizeInput.call(input)
    })

    return () => {
      selectAll.forEach((select) => {
        select.removeEventListener('change', (event) => {})
      })

      inputAll.forEach((input) => {
        input.removeEventListener('input', resizeInput)
      })
    }
  }, []);

  useEffect(() => {
    verificationAddArgument(argumentName)
    return () => {
      // Component unmount code.
    };
  }, [argumentType])

  useEffect(() => {
    verificationAddComputation(computationName)
    return () => {
      // Component unmount code.
    };
  }, [computationType])

  useEffect(() => {
    verificationAddSet(setName)
    return () => {
      // Component unmount code.
    };
  }, [setType])

  useEffect(() => {
    onIsArgumentByName(argumentName)
    onIsComputationByName(computationName)
    onIsSetByName(setName)
    onIsConditionByName(conditionName)
    onIsConclusionByName(conclusionName)
    verificationAddArgumentType(argumentType)

    codeGeneration()

    return () => {
      // Component unmount code.
    };
  }, [code])

  useEffect(() => {
    codeGeneration()

    return () => {
      // Component unmount code.
    };
  }, [lemmaName])

  useEffect(() => {
    //console.log(execs)
    let exec = execs.find(item => item.method === method)
    if (exec !== undefined && exec.status === 'success') {
      setIsExec(true)
    } else {
      setIsExec(false)
    }
    return () => {
      // Component unmount code.
    };
  }, [execs])

  const onSample = () => {
    setCode({
      arguments: code.arguments,
      addArgument: [],
      computation: code.computation,
      addComputation: [{name: "msg_sender", type: "l"},{name: "balances[msg_sender]", type: "l"},{name: "balances[to_]", type: "l"},{name: "balances[to_]", type: "l'"}],
      addSet: [],
      addCondition: [{name: "to_ <> msg_sender", type: ""},{name: "uint2N balances_msg_sender >= uint2N value_", type: ""}],
      addConclusion: [{name: "balances_to_' = xIntPlus balances_to_ value_", type: ""}]
    })
  }

  const codeGeneration = () => {
    let tCode = 'Lemma '+method+'_'+lemmaName+'_def '+code.arguments
    if (code.addArgument !== undefined) {
      code.addArgument.map((a, i) => (
        tCode += '('+a.name+' : '+a.type+') '
      ))
    }
    tCode += code.computation
    code.addComputation.map((a, i) => (
      tCode += '\nelpi read_fields('+a.type+') "'+a.name+'".'
    ))
    //???
    /*
    if (code.addCondition !== undefined && code.addCondition.length > 0) {
      tCode += '\ncondition('+code.addCondition+').'
    }
    */
    if (code.addSet !== undefined) {
      code.addSet.map((a, i) => (
        tCode += '\nset ('+a.name+' := '+a.type+').'
      ))
    }
    if (code.addCondition !== undefined) {
      code.addCondition.map((a, i) => {
        tCode += '\n'
        tCode += 'condition('
        tCode += a.name+').'
      })
    }
    if (code.addConclusion !== undefined) {
      code.addConclusion.map((a, i) => {
        tCode += '\n'
        if (code.addConclusion.length === 1) {
          tCode += 'conclusion('
        } else {
          tCode += 'addConclusion('
        }
        tCode += a.name+').'
      })
    }
    tCode += '\nDefined.'
    setTextCode(tCode)
  }

  const onIsArgumentByName = (name) => {
    const unique = isUnique(code.addArgument, name, argumentType)
    if (unique.name) {
      setIsArgumentNameVerification(true)
    } else {
      setIsArgumentNameVerification(false)
    }
    if (!unique.name && unique.type) {
      setIsArgumentTypeVerification(true)
    } else {
      setIsArgumentTypeVerification(false)
    }
  }

  const onIsComputationByName = (name) => {
    const unique = isUnique(code.addComputation, name, computationType)
    if (unique.type) {
      setIsComputationTypeVerification(true)
    } else {
      setIsComputationTypeVerification(false)
    }
    if (!unique.name && unique.type) {
      setIsComputationNameTypeVerification(true)
    } else {
      setIsComputationNameTypeVerification(false)
    }
  }

  const onIsSetByName = (name) => {
    const unique = isUnique(code.addSet, name, setType)
    if (unique.name) {
      setIsSetNameVerification(true)
    } else {
      setIsSetNameVerification(false)
    }
    if (!unique.name && unique.type) {
      setIsSetTypeVerification(true)
    } else {
      setIsSetTypeVerification(false)
    }
  }

  const onIsConditionByName = (name) => {
    const unique = isUnique(code.addCondition, name, '')
    if (unique.name) {
      setIsConditionNameVerification(true)
    } else {
      setIsConditionNameVerification(false)
    }
  }

  const onIsConclusionByName = (name) => {
    const unique = isUnique(code.addConclusion, name, '')
    if (unique.name) {
      setIsConclusionNameVerification(true)
    } else {
      setIsConclusionNameVerification(false)
    }
  }

  const changeMethod = (e) => {
    ////this.setState({ value: event.target.value })
    let isMethod = false
    if (e.target.value.length > 0) {
      const v = JSON.parse(e.target.value)
      let argumentsType = ''
      let arguments_ = ''
      if (isObject(v)) {
        isMethod = true
        for (let key in v) {
          if (isObject(v[key])) {
            Object.keys(v[key]).map((n, i) => {
              //console.log(v[key][n])
              argumentsType += '(' + n +' : ' + v[key][n] + ') '
              arguments_ += ' ' + n
            })
          }
        }
        const m = e.target[e.target.selectedIndex].text.replace('⇘ ','').replace('⇗ ','')
        setMethod(m)

        let exec = execs.find(item => item.method === m)
        if (exec !== undefined && exec.status === 'success') {
          setIsExec(true)
        } else {
          setIsExec(false)
        }

        setCode({
          arguments: argumentsType,
          addArgument: code.addArgument,
          computation: '(l: LedgerLRecord rec) : Prop. \n' + 'add_computation l (' + m + ' rec def' + arguments_ + ').',
          addComputation: code.addComputation,
          addSet: code.addSet,
          addCondition: code.addCondition,
          addConclusion: code.addConclusion
        })

        if (lemmaName.length > 0) {
          setIsVerification(true)
        }
      }
    }

    if (!isMethod) {
      setMethod('')
      setCode({
        arguments: '',
        addArgument: code.addArgument,
        computation: '',
        addComputation: code.addComputation,
        addSet: code.addSet,
        addCondition: code.addCondition,
        addConclusion: code.addConclusion
      })
      setIsVerification(false)
    }
  }

  const verificationLemmaName = (value) => {
    let v = value.replace(/\ /g, '')
    setLemmaName(v)
  }

  const isUnique = (obj, name, type) => {
    let result = {name:true, type: true}
    if (isObject(obj)) {
      for (let j in obj) {
        if (obj[j].name) {
          if (obj[j].name === name) {
            result.name = false
            if (obj[j].type && obj[j].type === type) {
              result.type = false
            }
          }
        }
      }
    }
    return result
  }

  const verificationAddByMask = (value, mask) => {
    let result = value
    let w = 0
    let f = 0
    for (let i = 0; i < value.length; i++) {
      if (f === 0 && mask.indexOf(value[i]) >= 0) { w++ } else { f++ }
    }
    if (w > 0 ) { result = value.substring(w) }
    return result
  }

  const verificationAddArgument = (value) => {
    const v = verificationAddByMask(value, "0123456789")
    onIsArgumentByName(v)
    setArgumentName(v)
  }

  /*
  const changeArgumentType = (e) => {
    setArgumentType(e.target.value)
  }
  */

  // Start argumentTypeOptions
  //const argumentTypeOptions = []
  const argumentTypes = []
  let i = 0
  const asb = ['address', 'string', 'bool']
  for (const j in asb) {
    //argumentTypeOptions.push(<option key={'option-argument-type'+j} value={asb[j]}>{asb[j]}</option>)
    argumentTypes.push(asb[j])
    i = j
  }
  const uii = ['uint', 'int']
  for (const m in uii) {
    let n = 8
    while (n <= 256) {
      i++
      //argumentTypeOptions.push(<option key={'option-argument-type'+i} value={uii[m]+n}>{uii[m]+n}</option>)
      argumentTypes.push(uii[m]+n)
      n += 8
    }
  }
  // End argumentTypeOptions

  const verificationAddArgumentType = (value) => {
    const v = verificationAddByMask(value, "0123456789")
    ////onIsArgumentByName(v)
    setIsArgumentBasicTypeVerification(argumentTypes.includes(v))
    setIsArgumentCustomTypeVerification(curContract.contractCustomTypes.includes(v))
    setArgumentType(v)
  }

  const choose = (e, a, setName, setType, root) => {
    e.preventDefault()
    if (a.name && setName !== undefined) {
      setName(a.name)
      const elName = document.getElementById(root+"-name")
      if (elName !== undefined) {
        elName.value = a.name
        let event = new Event("input")
        elName.dispatchEvent(event)
      }
    }
    if (a.type && setType !== undefined) {
      setType(a.type)
      const elType = document.getElementById(root+"-type")
      if (elType !== undefined) {
        elType.value = a.type
        let event = new Event("change")
        elType.dispatchEvent(event)
      }
    }
  }

  const setCodeForAddArgument  = (value) => {
    setCode({
      arguments: code.arguments,
      addArgument: value,
      computation: code.computation,
      addComputation: code.addComputation,
      addSet: code.addSet,
      addCondition: code.addCondition,
      addConclusion: code.addConclusion
    })
  }
  const addArgument = (e) => {
    e.preventDefault()
    if (isUnique(code.addArgument, argumentName, argumentType).name) {
      let item = code.addArgument
      item.push({name: argumentName, type:argumentType})

      setCodeForAddArgument(item)
    }
  }

  const updateArgument = (e) => {
    e.preventDefault()
    if (!isUnique(code.addArgument, argumentName, argumentType).name) {
      let item = code.addArgument

      if (isObject(item)) {
        for (let j in item) {
          if (item[j].name && item[j].name === argumentName) {
            item[j].type = argumentType
          }
        }
      }

      setCodeForAddArgument(item)
    }
  }

  const deleteArgument = (e, i) => {
    e.preventDefault()
    let item = code.addArgument
    if (item.length > i) {
      item.splice(i, 1)

      setCodeForAddArgument(item)
    }
  }

  const verificationAddComputation = (value) => {
    const v = verificationAddByMask(value, "0123456789/[]{},%.?")
    onIsComputationByName(v)
    setComputationName(v)
  }

  const changeComputationType = (e) => {
    setComputationType(e.target.value)
  }

  const setCodeForAddComputation  = (value) => {
    setCode({
      arguments: code.arguments,
      addArgument: code.addArgument,
      computation: code.computation,
      addComputation: value,
      addSet: code.addSet,
      addCondition: code.addCondition,
      addConclusion: code.addConclusion
    })
  }

  const addComputation = (e) => {
    e.preventDefault()
    if (isUnique(code.addComputation, computationName, computationType).type) {
      let item = code.addComputation
      item.push({name: computationName, type:computationType})

      setCodeForAddComputation(item)
    }
  }

  const updateComputation = (e) => {
    e.preventDefault()
    const unique = isUnique(code.addComputation, computationName, computationType)
    if (!unique.name && unique.type) {
      let item = code.addComputation

      if (isObject(item)) {
        for (let j in item) {
          if (item[j].name && item[j].name === computationName) {
            item[j].type = computationType
          }
        }
      }

      setCodeForAddComputation(item)
    }
  }

  const deleteComputation = (e, i) => {
    e.preventDefault()
    let item = code.addComputation
    if (item.length > i) {
      item.splice(i, 1)

      setCodeForAddComputation(item)
    }
  }

  const verificationAddSet = (value) => {
    const v = verificationAddByMask(value, "0123456789")
    onIsSetByName(v)
    setSetName(v)
  }

  const verificationAddSetType = (value) => {
    //const v = verificationAddByMask(value, "0123456789")
    ////onIsArgumentByName(v)
    //setIsArgumentBasicTypeVerification(argumentTypes.includes(v))
    //setIsArgumentCustomTypeVerification(curContract.contractCustomTypes.includes(v))
    setSetType(value)
  }

  const setCodeForAddSet  = (value) => {
    setCode({
      arguments: code.arguments,
      addArgument: code.addArgument,
      computation: code.computation,
      addComputation: code.addComputation,
      addSet: value,
      addCondition: code.addCondition,
      addConclusion: code.addConclusion
    })
  }
  const addSet = (e) => {
    e.preventDefault()
    if (isUnique(code.addSet, setName, setType).name) {
      let item = code.addSet
      item.push({name: setName, type:setType})

      setCodeForAddSet(item)
    }
  }

  const updateSet = (e) => {
    e.preventDefault()
    if (!isUnique(code.addSet, setName, setType).name) {
      let item = code.addSet

      if (isObject(item)) {
        for (let j in item) {
          if (item[j].name && item[j].name === setName) {
            item[j].type = setType
          }
        }
      }

      setCodeForAddSet(item)
    }
  }

  const deleteSet = (e, i) => {
    e.preventDefault()
    let item = code.addSet
    if (item.length > i) {
      item.splice(i, 1)

      setCodeForAddSet(item)
    }
  }

  const verificationAddCondition = (value) => {
    const v = verificationAddByMask(value, "0123456789/\\<>=()' ")
    onIsConditionByName(v)
    setConditionName(v)
  }

  const setCodeForAddCondition  = (value) => {
    setCode({
      arguments: code.arguments,
      addArgument: code.addArgument,
      computation: code.computation,
      addComputation: code.addComputation,
      addSet: code.addSet,
      addCondition: value,
      addConclusion: code.addConclusion
    })
  }

  const addCondition = (e) => {
    e.preventDefault()
    if (isUnique(code.addCondition, conditionName, '').name) {
      let item = code.addCondition
      item.push({name: conditionName, type:''})

      setCodeForAddCondition(item)
    }
  }

  const deleteCondition = (e, i) => {
    e.preventDefault()
    let item = code.addCondition
    if (item.length > i) {
      item.splice(i, 1)

      setCodeForAddCondition(item)
    }
  }

  const verificationAddConclusion = (value) => {
    const v = verificationAddByMask(value, "0123456789/\\<>=()' ")
    onIsConclusionByName(v)
    setConclusionName(v)
  }

  const setCodeForAddConclusion  = (value) => {
    setCode({
      arguments: code.arguments,
      addArgument: code.addArgument,
      computation: code.computation,
      addComputation: code.addComputation,
      addSet: code.addSet,
      addCondition: code.addCondition,
      addConclusion: value
    })
  }

  const addConclusion = (e) => {
    e.preventDefault()
    if (isUnique(code.addConclusion, conclusionName, '').name) {
      let item = code.addConclusion
      item.push({name: conclusionName, type:''})

      setCodeForAddConclusion(item)
    }
  }

  const deleteConclusion = (e, i) => {
    e.preventDefault()
    let item = code.addConclusion
    if (item.length > i) {
      item.splice(i, 1)

      setCodeForAddConclusion(item)
    }
  }

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id)
  }

  return (
    <div className="overflow-hidden content-section" id="content-add-proposition">
      <h2>add proposition</h2>
      <pre>
        <div className="project-name">
          Project Name: <span className="code-example">{curContract.contractName}</span>
        </div>

        <div className='container'>
            <div className='tabs'>
                {tabs.map((tab, i) =>
                  <button key={i} id={tab.id} disabled={currentTab === `${tab.id}`} onClick={(handleTabClick)}>{tab.title}</button>
                )}
            </div>
            <div className='tab-content'>
              {/* TAB Editor */}
              <div className={`code all-tab ${(currentTab === `1`) && ("current-tab")}`}>
                {'Lemma '}
                {/* START select Method */}
                <div className="select_">
                  <select name="select" onChange={changeMethod}>
                    <option key={'optionAP--0'} value="">Select Method:</option>
                    {Object.keys(meta).map((key, ikey) => (
                      <React.Fragment key={'select-meta-'+key}>
                        {(key === 'ContractMethods') && (
                          <React.Fragment key={'method-'+key}>
                            {Object.keys(meta[key]).map((a, j) => (
                              <React.Fragment key={'a--'+a}>
                                {Object.keys(meta[key][a]).map((b, n) => (
                                  <React.Fragment key={'b--'+b}>
                                    {b === 'arguments' && (
                                      <option
                                        key={'optionAP--'+b}
                                        value={JSON.stringify(meta[key][a][b])}
                                      >
                                        {/*
                                        {execs.find(item => item.method === a && item.status === 'success')
                                          ?
                                          <React.Fragment>success </React.Fragment>
                                          :
                                          <React.Fragment>--- </React.Fragment>
                                        }
                                        */}
                                        {curContract.externalPublicFunctions.find(item => item === a)
                                          ?
                                          <React.Fragment>⇗ </React.Fragment>
                                          :
                                          <React.Fragment>⇘ </React.Fragment>
                                        }
                                        {a}
                                      </option>
                                    )}
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ))}
                  </select>
                  <div className="editor"/>
                </div>
                {/* END select Method */}
                {'_'}
                {/* START input_ + button */}
                <div className="input_">
                  <input type="text"
                         id="lemma-name"
                         className="add-p-input"
                         value={lemmaName}
                         pattern="^[A-Za-z0-9_]{1,36}" required maxLength="36"
                         onKeyPress={e => keyPressAZ09_(e)}
                         onChange={e => verificationLemmaName(e.target.value)}
                  />
                  <div className="editor"/>
                </div>
                {/* END input_ + button */}
                {/*
                {lemmaName}
                 */}
                {'_def '}
                <div className={`no-verification ${(isVerification) && ("is-verification")}`}>

                  {/* AddArgument */}
                  <AddArgument
                    argumentName={argumentName}
                    choose={choose}
                    setArgumentName={setArgumentName}
                    setArgumentType={setArgumentType}
                    isLoader={isLoader}
                    deleteArgument={deleteArgument}
                    verificationAddArgument={verificationAddArgument}
                    isArgumentBasicTypeVerification={isArgumentBasicTypeVerification}
                    argumentType={argumentType}
                    isArgumentCustomTypeVerification={isArgumentCustomTypeVerification}
                    verificationAddArgumentType={verificationAddArgumentType}
                    isVerification={isVerification}
                    isArgumentNameVerification={isArgumentNameVerification}
                    updateArgument={updateArgument}
                    addArgument={addArgument}
                    isArgumentTypeVerification={isArgumentTypeVerification}
                    code={code}
                  />

                  {/* AddComputation */}
                  <AddComputation
                    computationName={computationName}
                    choose={choose}
                    setComputationName={setComputationName}
                    setComputationType={setComputationType}
                    isLoader={isLoader}
                    deleteComputation={deleteComputation}
                    verificationAddComputation={verificationAddComputation}
                    changeComputationType={changeComputationType}
                    isVerification={isVerification}
                    isComputationTypeVerification={isComputationTypeVerification}
                    addComputation={addComputation}
                    isComputationNameTypeVerification={isComputationNameTypeVerification}
                    updateComputation={updateComputation}
                    code={code}
                  />

                  {/* AddSet */}
                  <AddSet
                    setName={setName}
                    choose={choose}
                    setSetName={setSetName}
                    setSetType={setSetType}
                    isLoader={isLoader}
                    deleteSet={deleteSet}
                    verificationAddSet={verificationAddSet}
                    setType={setType}
                    verificationAddSetType={verificationAddSetType}
                    isVerification={isVerification}
                    isSetNameVerification={isSetNameVerification}
                    updateSet={updateSet}
                    addSet={addSet}
                    isSetTypeVerification={isSetTypeVerification}
                    code={code}
                  />

                  {/* AddCondition */}
                  <AddCondition
                    conditionName={conditionName}
                    choose={choose}
                    verificationAddCondition={verificationAddCondition}
                    isLoader={isLoader}
                    isVerification={isVerification}
                    isConditionNameVerification={isConditionNameVerification}
                    addCondition={addCondition}
                    code={code}
                    setConditionName={setConditionName}
                    deleteCondition={deleteCondition}
                  />

                  {/* AddConclusion */}
                  <AddConclusion
                    conclusionName={conclusionName}
                    choose={choose}
                    verificationAddConclusion={verificationAddConclusion}
                    isLoader={isLoader}
                    isVerification={isVerification}
                    isConclusionNameVerification={isConclusionNameVerification}
                    addConclusion={addConclusion}
                    code={code}
                    setConclusionName={setConclusionName}
                    deleteConclusion={deleteConclusion}
                  />

                  <div>Defined.</div>
                </div>
              </div>

              {/* TAB Code */}
              <div className={`all-tab ${(currentTab === `2`) && ("current-tab")}`}>
                <div className={`no-verification ${(isVerification) && ("is-verification")}`}>
                  {textCode}
                </div>
              </div>

              {/* TAB JSON */}
              <div className={`all-tab ${(currentTab === `3`) && ("current-tab")}`}>
                <div className={`no-verification ${(isVerification) && ("is-verification")}`}>
                  {/*
                  {textCode}
                  */}
                  {JSON.stringify(json, null, 2)}
                  {/*
                  <code className="json hljs">
                    Example:<br/>
                    {JSON.stringify(json, null, 2)}
                  </code>
                  */}
                </div>
              </div>
            </div>
        </div>

        <div>
          JSON Response:<br/>
          <span className="code-example">
            {JSON.stringify(jsonResponse, null, 2)}
            <br/>
          </span>
        </div>

        <div className="input_">
          <input type="text"
                 id="ce-timeout-in-app-p"
                 className="add-p-input"
                 value={timeoutCE}
                 pattern="^[0-9]{1,4}" required maxLength="4"
                 onKeyPress={e => keyPress09(e)}
                 onChange={e => onTimeout(e.target.value, true)}
          />
          <div className="editor"/>
        </div>

        <button
          key={createExecUrl}
          className="api-btn"
          type="button"
          disabled={isLoader || !isVerification || timeoutCE.length === 0 || timeoutCE < 1 || isExec}
          onClick={(event) => onPost(event, createExecUrl, createExecJson)}
        >
           Create Exec
        </button>

        <div className="input_">
          <input type="text"
                 id="add-p-timeout"
                 className="add-p-input"
                 value={timeout}
                 pattern="^[0-9]{1,4}" required maxLength="4"
                 onKeyPress={e => keyPress09(e)}
                 onChange={e => onTimeout(e.target.value, false)}
          />
          <div className="editor"/>
        </div>

        <button
          key={url}
          className="api-btn"
          type="button"
          disabled={isLoader || !isVerification || timeout.length === 0 || timeout < 1 || !isExec}
          onClick={(event) => onPost(event, url, json)}
        >
           Add Proposition
        </button>

        {/* for test !!! */}
        <button
          key={url+100}
          className="api-btn"
          type="button"
          disabled={isLoader || !isVerification || timeout.length === 0 || timeout < 1 || !isExec}
          onClick={(event) => onSample()}
        >
           +
        </button>
      </pre>

      <p>Add proposition.<br/>
        You need to make a POST call to the following url:<br/>
        <i>/{url}</i>
      </p>
      <br/>
      <h4>QUERY PARAMETERS</h4>
      <table className="central-overflow-x">
        <thead>
        <tr>
          <th>Field</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>auth*</td>
          <td>Object</td>
          <td>Authorization</td>
        </tr>
        <tr>
          <td>contract*</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>function*</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>name*</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>code*</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>timeout*</td>
          <td>Number</td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <table className="sub-table">
        <thead></thead>
        <tbody>
        <tr>
          <td>* - required</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AddProposition;
