import React, {useEffect, useState} from 'react'
import './create-exec.css'
import { keyPress09 } from '../../utils/key-press-09'
import { isTimeout } from '../../utils/timeout'
import Code from '../code'

const CreateExec = ({api, api_, auth, session, onPost, isLoader, execs, curContract}) => {
  const [method, setMethod] = useState('')
  const [isCreateExec, setIsCreateExec] = useState(false)
  const [timeout, setTimeout] = useState(240)

  let url = 'create-exec'

  let json = {
    auth: auth,
    contract: curContract.contractName,
    function: method,
    timeout: timeout
  }

  let jsonRequest = {
    auth: {
      key: "...",
      signature: "...",
      data: "text",
      encoding: auth.encoding
    },
    contract: curContract.contractName,
    function: method,
    timeout: timeout
  }

  let jsonResponse = {
    base: url,
    function: method
  }

  useEffect(() => {
    let exec = execs.find(item => item.method === method)
    if (exec !== undefined && exec.status === 'success') {
      setIsCreateExec(true)
    } else {
      setIsCreateExec(false)
    }
    return () => {
      // Component unmount code.
    };
  }, [execs, method])

  const changeMethod = (e) => {
    setMethod(e.target.value)
  }

  const onTimeout = (value) => {
    setTimeout(isTimeout(value))
  }

  return (
    <div className="overflow-hidden content-section" id="content-create-exec">
      <h2>create exec</h2>
      <pre>
        <Code api_ = {api_}
              url = {url}
              jsonRequestLabel = "JSON Request"
              jsonResponseLabel = "JSON Response"
              jsonRequest = {jsonRequest}
              jsonResponse = {jsonResponse}
              commentForResponse = "+ asynchronous socket return"
        />

        {/* START select Method */}
        <div className="select_">
                  <select name="select" onChange={changeMethod}>
                    <option key={'optionCE--0'} value="">Select Method:</option>
                    {Object.keys(execs).map((key, n) => (
                      <React.Fragment key={'optionCE-'+key}>

                        {/*{execs[key].status !== 'success' && (*/}
                          <option
                            key={'optionCE--'+key}
                            value={execs[key].method}
                          >
                            {curContract.externalPublicFunctions.find(item => item === execs[key].method)
                              ?
                              <React.Fragment>⇗ </React.Fragment>
                              :
                              <React.Fragment>⇘ </React.Fragment>
                            }
                            {execs[key].method}
                          </option>
                        {/*)}*/}

                      </React.Fragment>
                    ))}
                  </select>
                  <div className="editor"/>
                </div>
        {/* END select Method */}

        <div className="input_">
          <input type="text"
                 id="ce-timeout"
                 className="add-p-input"
                 value={timeout}
                 pattern="^[0-9]{1,4}" required maxLength="4"
                 onKeyPress={e => keyPress09(e)}
                 onChange={e => onTimeout(e.target.value)}
          />
          <div className="editor"/>
        </div>

        <button
          key={url}
          className="api-btn"
          type="button"
          disabled={isLoader || method.length === 0 || timeout.length === 0 || timeout < 1 || isCreateExec}
          onClick={(event) => onPost(event, url, json)}
        >
           Create Exec
        </button>
        {isCreateExec && (
          <div className="check"> </div>
        )}
      </pre>
      <p>
        Compile translated Ursus file.<br/>
        You need to make a POST call to the following url:<br/>
        <i>/{url}</i>
      </p>
      <br/>
      <h4>QUERY PARAMETERS</h4>
      <table className="central-overflow-x">
        <thead>
        <tr>
          <th>Field</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>auth*</td>
          <td>Object</td>
          <td>Authorization</td>
        </tr>
        <tr>
          <td>contract*</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>function*</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>timeout*</td>
          <td>Number</td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <table className="sub-table">
        <thead></thead>
        <tbody>
        <tr>
          <td>* - required</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CreateExec;
