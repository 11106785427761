import React from "react"
import { keyPressAZ09___ } from '../../utils/key-press-az09___'

type CProps = {
  conclusionName: string;
  choose: any;
  verificationAddConclusion: any;
  isLoader: any;
  isVerification: any;
  isConclusionNameVerification: any;
  addConclusion: any;
  code: any;
  setConclusionName: any;
  deleteConclusion: any;
  children?: React.ReactNode; // 👈️ added type for children
}

const AddConclusion = (props: CProps) => {
  const conclusionName = props.conclusionName
  const choose = props.choose
  const verificationAddConclusion = props.verificationAddConclusion
  const isLoader = props.isLoader
  const isVerification = props.isVerification
  const isConclusionNameVerification = props.isConclusionNameVerification
  const addConclusion = props.addConclusion
  const code = props.code
  const setConclusionName = props.setConclusionName
  const deleteConclusion = props.deleteConclusion

  return <>
    {(code.addConclusion !== undefined) && (
      <React.Fragment>
        {code.addConclusion.map((a, i) => (
          <div key={'add-conclusion-'+i} className="conclusionCode_">
            {'\n'}
            <div className={`conclusionCode ${(conclusionName === a.name) && ("activeCode")}`}>
              <div className="conclusionNameType" onClick={(e) => choose(e, a, setConclusionName, undefined, "conclusion")}>
                {code.addConclusion.length === 1
                  ?
                  <React.Fragment>conclusion(</React.Fragment>
                  :
                  <React.Fragment>addConclusion(</React.Fragment>
                }
                <div className={`conclusionName ${(conclusionName === a.name) && ("activeName")}`}>{a.name}</div>).
              </div>
              <button
                title={`Delete - ${a.name}`}
                key='del-conclusion'
                className="del-btn"
                type="button"
                disabled={isLoader || !isVerification}
                onClick={(e) => deleteConclusion(e, i)}
              >
                <div className="icon del-icon"/>
              </button>
            </div>
          </div>
        ))}
      </React.Fragment>
    )}

    <br/>
    <div className="add-input-select">
      {/* START input_ */}
      <div className="input_">

        <input type="text"
               id="conclusion-name"
               className="add-p-input"
               value={conclusionName}
               pattern="^[A-Za-z0-9_\/\<>=\(\)' ]{0,47}" required maxLength="47"
               disabled={isLoader || !isVerification}
               onKeyPress={e => keyPressAZ09___(e)}
               onChange={e => verificationAddConclusion(e.target.value)}
        />
        <div className="editor"/>
      </div>
      {/* END input_ + button */}

      <button
        title="Add"
        key='add-conclusion'
        className="add-btn"
        type="button"
        disabled={isLoader || !isVerification || !isConclusionNameVerification || conclusionName.length  === 0}
        onClick={(e) => addConclusion(e)}
      >
        <div className="icon add-icon"/>
      </button>

      <div className="add-label add-label-left24">Conclusion</div>
    </div>
    {/* &nbsp; */}
  </>
}

export default AddConclusion
