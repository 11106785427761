import React from "react"
import { keyPressAZ09__ } from '../../utils/key-press-az09__'

type CProps = {
  computationName: string;
  choose: any;
  setComputationName: any;
  setComputationType: any;
  isLoader: any;
  deleteComputation: any;
  verificationAddComputation: any;
  changeComputationType: any;
  isVerification: any;
  isComputationTypeVerification: any;
  addComputation: any;
  isComputationNameTypeVerification: any;
  updateComputation: any;
  code: any;
  children?: React.ReactNode; // 👈️ added type for children
}

const AddComputation = (props: CProps) => {
  const computationName = props.computationName
  const choose = props.choose
  const setComputationName = props.setComputationName
  const setComputationType = props.setComputationType
  const isLoader = props.isLoader
  const deleteComputation = props.deleteComputation
  const verificationAddComputation = props.verificationAddComputation
  const changeComputationType = props.changeComputationType
  const isVerification = props.isVerification
  const isComputationTypeVerification = props.isComputationTypeVerification
  const addComputation = props.addComputation
  const isComputationNameTypeVerification = props.isComputationNameTypeVerification
  const updateComputation = props.updateComputation
  const code = props.code

  return <>
    {(code.computation !== undefined && code.computation.length > 0) && (
      <React.Fragment>{code.computation}</React.Fragment>
    )}

    {(code.addComputation !== undefined) && (
      <React.Fragment>
        {code.addComputation.map((a, i) => (
          <div key={'add-computation-'+i} className="computationCode_">
            {'\n'}
            <div className={`computationCode ${(computationName === a.name) && ("activeCode")}`}>
              <div className="computationNameType" onClick={(e) => choose(e, a, setComputationName, setComputationType, "computation")}>
                {'elpi read_fields('}<div className="computationType">{a.type}</div>) <div className={`computationName ${(computationName === a.name) && ("activeName")}`}>"{a.name}"</div>.
              </div>
              <button
                title={`Delete - ${a.name}`}
                key='del-computation'
                className="del-btn"
                type="button"
                disabled={isLoader || !isVerification}
                onClick={(e) => deleteComputation(e, i)}
              >
                <div className="icon del-icon"/>
              </button>
            </div>
          </div>
        ))}
      </React.Fragment>
    )}

    <br/>
    <div className="add-input-select">
      {/* START input_ */}
      <div className="input_">

        <input type="text"
               id="computation-name"
               className="add-p-input"
               value={computationName}
               pattern="^[A-Za-z0-9_\/[]\{\},%.?]{0,48}" maxLength="48"
               disabled={isLoader || !isVerification}
               onKeyPress={e => keyPressAZ09__(e)}
               onChange={e => verificationAddComputation(e.target.value)}
        />
        <div className="editor"/>
      </div>
      {/* END input_ + button */}

      {/* START select ComputationType */}
      <div className="select_">
        <select id="computation-type"
                name="select"
                onChange={changeComputationType}
                disabled={isLoader || !isVerification}
        >
          <option key={'option-computation-type0'} value="l">l - the initial state</option>
          <option key={'option-computation-type1'} value="l'">l' - after computation</option>
        </select>
        <div className="editor"/>
      </div>
      {/* END select ComputationType */}

      <button
        title="Add"
        key='add-computation'
        className="add-btn"
        type="button"
        disabled={isLoader || !isVerification || !isComputationTypeVerification || computationName.length  === 0}
        onClick={(e) => addComputation(e)}
      >
        <div className="icon add-icon"/>
      </button>

      {isLoader || !isVerification || !isComputationNameTypeVerification || computationName.length === 0
        ?
        ""
        :
        <button
          title={`Update Type for ${computationName}`}
          key='update-computation'
          className="update-btn"
          type="button"
          disabled={isLoader || !isVerification || !isComputationNameTypeVerification || computationName.length === 0}
          onClick={(e) => updateComputation(e)}
        >
          <div className="icon update-icon"/>
        </button>
      }
      <div className={`add-label ${(isLoader || !isVerification || !isComputationNameTypeVerification || computationName.length === 0) && ("add-label-left24")}`}>Computation</div>
    </div>
    &nbsp;
  </>
}

export default AddComputation
