import React from 'react'
import './get-ursus-meta.css'
import { isObject } from '../../utils/object'
import Code from '../code'

const GetUrsusMeta = ({api, api_, auth, session, onPost, isLoader, meta}) => {
  let url = 'get-ursus-meta'

  let json = {
    auth: auth,
    timeout: 120
  }

  let jsonRequest = {
    auth: {
      key: "...",
      signature: "...",
      data: "text",
      encoding: auth.encoding
    },
    timeout: 120
  }

  let jsonResponse = {
    base: url,
    session: "...",
    status: "success",
    meta: {}
  }

  /*
  //let str = '{"title":"Conference","date":"2017-11-30T12:00:00.000Z"}'
  let meetup = JSON.parse(m2, function(key, value) {
    console.log( key )
    console.log( value )
    if (key == 'ContractBasic') return value
    return value
  })
  console.log( meetup )
  */

  return (
    <div className="overflow-hidden content-section" id="content-get-ursus-meta">
      <h2>get ursus meta</h2>
      <pre>
        <Code api_ = {api_}
              url = {url}
              jsonRequestLabel = "JSON Request"
              jsonResponseLabel = "JSON Response"
              jsonRequest = {jsonRequest}
              jsonResponse = {jsonResponse}
              commentForResponse = "+ asynchronous socket return"
        />

        <button
          key={url}
          className="api-btn"
          type="button"
          disabled={isLoader || meta.length !== 0 || session.length <= 0}
          onClick={(event) => onPost(event, url, json)}
        >
           Get Ursus Meta
        </button>
        {meta.length !== 0 && (
          <React.Fragment>
          <div className="check"> </div>

          <div className="meta">
          <ul className="ul-meta">
            {Object.keys(meta).map((key, ikey) => (
              <li key={'li-meta-'+key}>
                {key}
                <ul className="ul-meta" key={'ulm-a-'+key}>
                  {Object.keys(meta[key]).map((a, ia) => (
                    <li key={'a-'+a}>
                      {a}
                      {isObject(meta[key][a])
                        ?
                        <ul className="ul-meta" key={'ulm-b-'+key}>
                          {Object.keys(meta[key][a]).map((b, ib) => (
                            <li key={'b-'+b}>
                              {b}
                              {isObject(meta[key][a][b])
                                ?
                                <ul className="ul-meta" key={'ulm-c-'+key}>
                                  {Object.keys(meta[key][a][b]).map((c, ic) => (
                                    <li key={'c-'+c}>
                                      {c}
                                      {isObject(meta[key][a][b][c])
                                        ?
                                        <ul className="ul-meta" key={'ulm-d-'+key}>
                                          {Object.keys(meta[key][a][b][c]).map((d, id) => (
                                            <li key={'d-'+d}>
                                              {d}
                                              {isObject(meta[key][a][b][c][d])
                                                ?
                                                <React.Fragment> = <b className="val-meta">Object</b></React.Fragment>
                                                :
                                                <React.Fragment> = <b className="val-meta">{meta[key][a][b][c][d]}</b></React.Fragment>
                                              }
                                            </li>
                                          ))}
                                        </ul>
                                        :
                                        <React.Fragment> = <b className="val-meta">{meta[key][a][b][c]}</b></React.Fragment>
                                      }
                                    </li>
                                  ))}
                                </ul>
                                :
                                <React.Fragment> = <b className="val-meta">{meta[key][a][b]}</b></React.Fragment>
                              }
                            </li>
                          ))}
                        </ul>
                        :
                        <React.Fragment> = <b className="val-meta">{meta[key][a]}</b></React.Fragment>
                      }
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          </div>
          </React.Fragment>
        )}
      </pre>
      <p>
        Get the resulted meta data from compiled Ursus contract.<br/>
        You need to make a POST call to the following url:<br/>
        <i>/{url}</i>

        {/*
        <code className="json hljs">
          Meta:<br/>
          {JSON.stringify(meta, null, 2)}
        </code>
        */}
      </p>

      {/*
        <ul className="logs">
          {meta.map((item, index) => (
            <React.Fragment key={'item--' + index}>
              {item.ContractBasic && (
                <li
                  key={'li-log-'+index}
                >
                  {index}
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      */}
      {/*
      <React.Fragment key={'first-key-'+key}>
        {key}
      </React.Fragment>
      */}

      <br/>
      <h4>QUERY PARAMETERS</h4>
      <table className="central-overflow-x">
        <thead>
        <tr>
          <th>Field</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>auth*</td>
          <td>Object</td>
          <td>Authorization</td>
        </tr>
        <tr>
          <td>timeout*</td>
          <td>Number</td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <table className="sub-table">
        <thead></thead>
        <tbody>
        <tr>
          <td>* - required</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default GetUrsusMeta;
