export function getEIP20() {
  let file = 'pragma ever-solidity >= 0.35.0;\n' +
    '\n' +
    '\n' +
    'contract EIP20 {\n' +
    '\n' +
    '    uint256 constant private MAX_UINT256 = 0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff;\n' +
    '    mapping (address => uint256) public balances;\n' +
    '    mapping (address => mapping (address => uint256)) public allowed;\n' +
    '    /*\n' +
    '    NOTE:\n' +
    '    The following variables are OPTIONAL vanities. One does not have to include them.\n' +
    '    They allow one to customise the token contract & in no way influences the core functionality.\n' +
    '    Some wallets/interfaces might not even bother to look at this information.\n' +
    '    */\n' +
    '    string public name;                   //fancy name: eg Simon Bucks\n' +
    '    uint8 public decimals;                //How many decimals to show.\n' +
    '    string public symbol;                 //An identifier: eg SBX\n' +
    '    uint256 public totalSupply;\n' +
    '    \n' +
    '\n' +
    '    constructor(\n' +
    '        uint256 _initialAmount,\n' +
    '        string /*memory*/ _tokenName,\n' +
    '        uint8 _decimalUnits,\n' +
    '        string /*memory*/ _tokenSymbol\n' +
    '    ) {\n' +
    '        balances[msg.sender] = _initialAmount;               // Give the creator all initial tokens\n' +
    '        totalSupply = _initialAmount;                        // Update total supply\n' +
    '        name = _tokenName;                                   // Set the name for display purposes\n' +
    '        decimals = _decimalUnits;                            // Amount of decimals for display purposes\n' +
    '        symbol = _tokenSymbol;                               // Set the symbol for display purposes\n' +
    '    }\n' +
    '\n' +
    '    function transfer(address _to, uint256 _value) public returns (bool success) {\n' +
    '        require(balances[msg.sender] >= _value);\n' +
    '        balances[msg.sender] -= _value;\n' +
    '        balances[_to] += _value;\n' +
    '        return true;\n' +
    '    }\n' +
    '\n' +
    '    function transferFrom(address _from, address _to, uint256 _value) public returns (bool success) {\n' +
    '        uint256 allowance_ = allowed[_from][msg.sender];\n' +
    '        require(balances[_from] >= _value && allowance_ >= _value);\n' +
    '        balances[_to] += _value;\n' +
    '        balances[_from] -= _value;\n' +
    '        if (allowance_ < MAX_UINT256) {\n' +
    '            allowed[_from][msg.sender] -= _value;\n' +
    '        }\n' +
    '        return true;\n' +
    '    }\n' +
    '\n' +
    '    function balanceOf(address _owner) public view returns (uint256 balance) {\n' +
    '        return balances[_owner];\n' +
    '    }\n' +
    '\n' +
    '    function approve(address _spender, uint256 _value) public returns (bool success) {\n' +
    '        allowed[msg.sender][_spender] = _value;\n' +
    '        return true;\n' +
    '    }\n' +
    '\n' +
    '    function allowance(address _owner, address _spender) public view returns (uint256 remaining) {\n' +
    '        return allowed[_owner][_spender];\n' +
    '    }\n' +
    '}\n'

  return file
}
