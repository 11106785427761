import React from "react"
type CProps = {
  url: string;
  api_: string;
  jsonRequestLabel: string;
  jsonResponseLabel: string;
  jsonRequest: any;
  jsonResponse: any;
  commentForResponse: string;
  children?: React.ReactNode; // 👈️ added type for children
}

const Code = (props: CProps) => {
  const url = props.url
  const api_ = props.api_
  const jsonRequestLabel = props.jsonRequestLabel
  const jsonResponseLabel = props.jsonResponseLabel
  const jsonRequest = props.jsonRequest
  const jsonResponse = props.jsonResponse
  const commentForResponse = props.commentForResponse

  return <>
    <code className="json hljs">
      {(url !== undefined && url.length > 0) && (
        <div>URL: <span className="code-example">{api_}{url}/</span></div>
      )}
      <br/>
      <div>
        {jsonRequestLabel}:<br/>
        <span className="code-example">
          {JSON.stringify(jsonRequest, null, 2)}
        </span>
      </div>
      <br/>
      <div>
        {jsonResponseLabel}:<br/>
        <span className="code-example">
          {JSON.stringify(jsonResponse, null, 2)}
          <br/>
          {commentForResponse}
        </span>
      </div>
    </code>
  </>
}

export default Code
