import React, {useEffect, useState} from 'react'
//import axios from 'axios'
import './translate.css'
import { keyPress09 } from '../../utils/key-press-09'
import { isTimeout } from '../../utils/timeout'
import { getEIP20 } from '../../utils/eip20'
import Code from '../code'

const Translate = ({api, api_, auth, sol, session, onPost, isLoader}) => {

  const [file, setFile] = useState('')
  const [fileName, setFileName] = useState('')
  const [timeout, setTimeout] = useState(60)

  let url = 'translate'
  let solFile

  let json = {
    auth: auth,
    file_name: fileName,
    file: file,
    timeout: timeout,
    language: "solidity",
    blockchain: "everscale",
    compiler_version: "test"
  }

  let checkJson = {
    auth: auth,
    file_name: fileName,
    check: true
  }

  let jsonRequest = {
    auth: {
      key: "...",
      signature: "...",
      data: "text",
      encoding: auth.encoding
    },
    file_name: fileName,
    file: "...",
    timeout: timeout,
    language: "solidity",
    blockchain: "everscale",
    compiler_version: "3.10.0"
  }

  let jsonResponse = {
    base: url
  }

  let checkJsonRequest = {
    auth: {
      key: "...",
      signature: "...",
      data: "text",
      encoding: auth.encoding
    },
    file_name: fileName,
    check: true
  }

  let checkJsonResponse = {
    base: url + ' ' + fileName
  }

  const onTimeout = (value) => {
    setTimeout(isTimeout(value))
  }

  const findName = (contract) => {
    let result = ''
    let arrContract = contract.split('contract')
    if (arrContract.length > 1) {
      let arrName = arrContract[1].trimStart().split(' ')
      if (arrName.length > 0) {
        result = arrName[0] + '.sol'
      }
    }
    return result
  }

  const isProtocol = (sol, protocol) => {
    let result = false
    const f = sol.toLowerCase().indexOf(protocol)
    if (f === 0) {
      result = true
    }
    return result
  }

  const isHttps = (sol) => {
    return isProtocol(sol, 'https://')
  }

  const isHttp = (sol) => {
    return isProtocol(sol, 'http://')
  }

  const onEIP20 = () => {
    setFile(getEIP20())
    setFileName(findName(getEIP20()))
  }

  React.useEffect(() => {
    if (sol && sol.length > 0) {
      //
    } else {
      solFile = document.getElementsByClassName('sol-file')
      solFile[0].addEventListener('change', (e) => {
        const file = e.target.files[0]
        if (file) {
          if (file.size <= 100*1024) {
            let extension = file.name.substring(file.name.lastIndexOf('.')+1)
            if (extension === 'sol') {
              setFileName(file.name)
              try {
                let reader = new FileReader()
                reader.onload = () => {
                  const text = reader.result
                  setFile(text)
                  setFileName(findName(text)) //
                }
                reader.readAsText(file)
              } catch (err) {
                console.error(err)
              }
            } else {
              console.log("The file must have extension - 'sol'")
            }
          } else {
            console.log("File size must not exceed - '100*1024'")
          }
        }
      })
    }
    return () => {
      try {
        if (sol && sol.length > 0) {
          //
        } else {
          if (solFile[0]) {
            solFile[0].removeEventListener('change', () => {})
          }
        }
      } catch (err) {
        //
      }
    }
  }, [])

  useEffect(() => {
    if (sol && sol.length > 0) {
      if (sol.toLowerCase() === 'eip20') {
        onEIP20()
      } else {
        setFile(sol)
        if (!isHttps(sol) && !isHttp(sol)) {
          setFileName(findName(sol))
        }
      }
    }
    return () => {
      // Component unmount code.
    }
  }, [sol])

  return (
    <div className="overflow-hidden content-section" id="content-translate">
      <h2>translate</h2>
      <pre>
        <Code api_ = {api_}
              url = {url}
              jsonRequestLabel = "JSON Request"
              jsonResponseLabel = "JSON Response"
              jsonRequest = {jsonRequest}
              jsonResponse = {jsonResponse}
              commentForResponse = "+ asynchronous socket return"
        />
        <Code api_ = ""
              url = ""
              jsonRequestLabel = "JSON Request for Check"
              jsonResponseLabel = "JSON Response for Check"
              jsonRequest = {checkJsonRequest}
              jsonResponse = {checkJsonResponse}
              commentForResponse = ""
        />

        {sol && sol.length > 0
          ? <span></span>
          :<div className="fileUploadWrapper">
            <input type="file" id="sol" className="fileUploadInput sol-file" accept=".sol"/>
            {file && file.length > 0
              ? <span className="fileUploadButtonOk">Choose File</span>
              : <span className="fileUploadButton">Choose File</span>
            }
          </div>
        }

        {/*
        {file && file.length > 0 && (
          <div className="check"> </div>
        )}
        */}

        <div className="input_">
          <input type="text"
                 id="tr-timeout"
                 className="add-p-input"
                 value={timeout}
                 pattern="^[0-9]{1,4}" required maxLength="4"
                 onKeyPress={e => keyPress09(e)}
                 onChange={e => onTimeout(e.target.value)}
          />
          <div className="editor"/>
        </div>

        <button
          key={url}
          className="api-btn"
          type="button"
          disabled={isLoader || (file.length === 0 || session.length === 0)}
          onClick={(event) => onPost(event, url, json)}
        >
           Translate
        </button>

        <button
          key={url+'-check'}
          className="api-btn"
          type="button"
          disabled={isLoader || (file.length === 0 || session.length === 0)}
          onClick={(event) => onPost(event, url, checkJson)}
        >
           Check
        </button>

      </pre>
      <p>
        Upload and translate the given source file to Ursus.<br/>
        You need to make a POST call to the following url:<br/>
        <i>/{url}</i>
      </p>
      <p>
        You can pass the contents of the file through the <strong>sol</strong> parameter or specify the value <strong>eip20</strong> a GET call to the following url:<br/>
        <i>{window.location.host}/?key=value&signature=value&data=value&sol=eip20</i>
      </p>
      <br/>
      <h4>QUERY PARAMETERS</h4>
      <table className="central-overflow-x">
        <thead>
        <tr>
          <th>Field</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>auth*</td>
          <td>Object</td>
          <td>Authorization</td>
        </tr>
        <tr>
          <td>file_name*</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>file*</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>timeout*</td>
          <td>Number</td>
          <td></td>
        </tr>
        <tr>
          <td>language</td>
          <td>String</td>
          <td>Default solidity</td>
        </tr>
        <tr>
          <td>blockchain</td>
          <td>String</td>
          <td>Default everscale</td>
        </tr>
        <tr>
          <td>compiler_version</td>
          <td>String</td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <table className="sub-table">
        <thead></thead>
        <tbody>
        <tr>
          <td>* - required</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <br/>
      <h4>OR</h4>
      <table className="central-overflow-x">
        <thead>
        <tr>
          <th>Field</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>auth*</td>
          <td>Object</td>
          <td>Authorization</td>
        </tr>
        <tr>
          <td>file_name*</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>check*</td>
          <td>Boolean</td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <table className="sub-table">
        <thead></thead>
        <tbody>
        <tr>
          <td>* - required</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Translate;
