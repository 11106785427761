import React, { useEffect, useState, useRef } from 'react'
import './App.css'
import Api from "./components/api"
import Notifications from "./components/notifications"
import { contract } from './utils/contract'
import { isObject } from './utils/object'
let api = 'http://localhost:4000/'
let api_ = api
if (window.location.host.indexOf('localhost') < 0) {
  api = '/backend/'
  api_ = window.location.origin + '/backend/'
}
let notifications = []

/*
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  },[value]);
  return ref.current;
}
*/

function App() {
  const queryParameters = new URLSearchParams(window.location.search)
  const key = queryParameters.get("key")
  const signature = queryParameters.get("signature")
  const data = queryParameters.get("data")
  const runAuth_ = queryParameters.get("run-auth")
  const demo_ = queryParameters.get("demo")
  const sol_ = queryParameters.get("sol")
  const encoding = queryParameters.get("encoding")

  const [auth, setAuth] = useState({
    key:undefined,
    signature:undefined,
    data:undefined,
    encoding: undefined
  })
  const [runAuth, setRunAuth] = useState(null)
  const [sol, setSol] = useState('')

  //const [secret, setSecret] = useState('test')
  const [session, setSession] = useState('')
  const [notification, setNotification] = useState({})

  const [meta, setMeta] = useState('')
  const [files, setFiles] = useState([])
  const [propositions, setPropositions] = useState([])

  const [curContract, setCurContract] = useState({
    contractCustomTypes: [],
    contractName: '?',
    externalPublicFunctions: [],
    contractMethods: []
  })

  let paths = {
    contract: '',
    execs: '',
    evals: '',
    spec: '',
    proofs: ''
  }

  const [startCreateExec, setStartCreateExec] = useState(false)
  const [statusCreateExec, setStatusCreateExec] = useState('')
  const [dataCreateExec, setDataCreateExec] = useState('')

  const [execs, setExecs] = useState([])
  //const prevExecs = usePrevious(execs)

  const [startTranslate, setStartTranslate] = useState(false)
  const [statusTranslate, setStatusTranslate] = useState('')
  //const prevTranslate = usePrevious(translate)

  const [startCompileTranslated, setStartCompileTranslated] = useState(false)
  const [statusCompileTranslated, setStatusCompileTranslated] = useState('')

  const [startCompileProp, setStartCompileProp] = useState(false)
  const [statusCompileProp, setStatusCompileProp] = useState('')
  const [dataCompileProp, setDataCompileProp] = useState('')

  const [startProve, setStartProve] = useState(false)
  const [statusProve, setStatusProve] = useState('')
  const [dataProve, setDataProve] = useState('')

  function decodeURI_(value) {
    const result = decodeURI(value.replace(/ /g, '+'))
    return result
  }

  useEffect(() => {
    //(async () => {
      //
    //})();

    setRunAuth(runAuth_)
    setSol(sol_)

    if (key !== null && signature != null && data !== null) {
      let encoding_ = undefined
      if (encoding !== null) { encoding_ = decodeURI_(encoding) }
      setAuth({
        key:decodeURI_(key),
        signature:decodeURI_(signature),
        data:decodeURI_(data),
        encoding: encoding_
      })
    } else {
      //TEST
      /*
      //base64
      http://localhost:3001/?key=MCowBQYDK2VwAyEAeIEcqkyg9hwJpEIYVMYoweHZZ2ViP1qMXanm91UYtTE=&signature=cxLD+Ny5907v/7tKlCXR/zY1kr2cXKdLzH3lOUy+wstXoZDe3fJ3ixkoI4SccFaRGLG6/9SDt1PQxIhT99JSAg==&data=gosh
      //hex
      http://localhost:3001/?key=302a300506032b657003210078811caa4ca0f61c09a4421854c628c1e1d96765623f5a8c5da9e6f75518b531&signature=7312c3f8dcb9f74eefffbb4a9425d1ff363592bd9c5ca74bcc7de5394cbec2cb57a190deddf2778b192823849c70569118b1baffd483b753d0c48853f7d25202&data=gosh&encoding=hex
      //sol
      http://localhost:3001/?sol=eip20
      */
      // DEMO for gosh
      if (demo_ !== null) {
        setAuth({
          key:'MCowBQYDK2VwAyEAeIEcqkyg9hwJpEIYVMYoweHZZ2ViP1qMXanm91UYtTE=',
          signature:'cxLD+Ny5907v/7tKlCXR/zY1kr2cXKdLzH3lOUy+wstXoZDe3fJ3ixkoI4SccFaRGLG6/9SDt1PQxIhT99JSAg==',
          data:'gosh',
          encoding: 'base64'
        })
      }
    }

    return () => {
      // Component unmount code.
    };
  }, [])

  useEffect(() => {
    //if (secret.length > 0) {
      let socket
      if (window.location.host.indexOf('localhost') < 0) {
        socket = new WebSocket(`wss://${window.location.host}/ws`)
      } else {
        socket = new WebSocket('ws://localhost:4001/ws')
      }
      socket.onopen = () => {
        //console.log('>> WS OPENED ✅')
        //if (session.length > 0) {
          //socket.send(JSON.stringify({session_id: session, secret}))
        socket.send(JSON.stringify({auth}))
        //}
      }

      socket.onmessage = (evt) => {
        //console.log('WebSocket connection message.')

        try {
          const data = JSON.parse(evt.data)
          if (!data.error) {
            //console.log(data)
            updateNotifications(data)
          } else {
            // do something with the incorrect format
          }
        } catch (err) {
          console.log(`⚠️ WEBSOCKET MESSAGE ERROR - ${err.message}`)
        }
      }

      socket.onerror = (error) => {
        console.log('>> WS ERROR: ', error.message)
      }

      socket.onclose = (error) => {
        //console.log('>> WS CLOSED -', error.message)
      }
    //}
    return () => {
      // Component unmount code.
    }
  }, [session])

  useEffect(() => {
    //console.log(notification)
    const base = notification['data'] && notification['data'].base
    switch (base) {
      case 'translate':
        setStatusTranslate('')
        setStartTranslate(true)
        break
      case 'compile-translated':
        setStatusCompileTranslated('')
        setStartCompileTranslated(true)
        break
      case 'create-exec':
        setStatusCreateExec('')
        setStartCreateExec(true)
        setDataCreateExec(notification['data'].function)
        break
      case 'add-proposition':
        findPropositions(files)
        break
      case 'compile-prop':
        setStatusCompileProp('')
        setStartCompileProp(true)
        setDataCompileProp(notification['data'].proposition)
        break
      case 'prove':
        setStatusProve('')
        setStartProve(true)
        setDataProve(notification['data'].proposition)
        break
      default:
        break
    }

    return () => {
      // Component unmount code.
    }
  }, [notification])

  useEffect(() => {
    if (startTranslate && statusTranslate.length > 0) {
      setNotification({status: statusTranslate, message:  'translate - ' + statusTranslate, data: {}})
      setStartTranslate(false)
    }
    return () => {
      // Component unmount code.
    }
  }, [statusTranslate])

  useEffect(() => {
    if (startCompileTranslated && statusCompileTranslated.length > 0) {
      setNotification({status: statusCompileTranslated, message:  'compile-translated - ' + statusCompileTranslated, data: {}})
      setStartCompileTranslated(false)
    }
    return () => {
      // Component unmount code.
    }
  }, [statusCompileTranslated])

  /*
  useEffect(() => {
    return () => {
      // Component unmount code.
    }
  }, [execs])
  */

  useEffect(() => {
    if (startCreateExec  && statusCreateExec.length > 0) {
      setNotification({status: statusCreateExec, message:  'create-exec for ' + dataCreateExec + ' - ' + statusCreateExec, data: {}})
      setStartCreateExec(false)
    }
    return () => {
      // Component unmount code.
    }
  }, [statusCreateExec])

  useEffect(() => {
    if (startCompileProp && statusCompileProp.length > 0) {
      setNotification({status: statusCompileProp, message:  'compile-prop for ' + dataCompileProp + ' - ' + statusCompileProp, data: {}})
      setStartCompileProp(false)
    }
    return () => {
      // Component unmount code.
    }
  }, [statusCompileProp])

  useEffect(() => {
    if (startProve && statusProve.length > 0) {
      let status = statusProve
      if (statusProve === 'ready') {
        status = 'success'
      }
      setNotification({status: statusProve, message:  'prove for ' + dataProve + ' - ' + status, data: {}})
      setStartProve(false)
    }
    return () => {
      // Component unmount code.
    }
  }, [statusProve])

  useEffect(() => {
    const artifacts = '/share/'+session+'/artifacts/'
    const contract = artifacts + 'coqbuild/'+curContract.contractName + '/'
    paths = {
        contract: contract,
        execs: contract + 'Execs/',
        evals: contract + 'Evals/',
        spec: contract + 'Spec/',
        proofs: contract + 'Proofs/'
    }

    if (startTranslate) {
      findTranslate(files)
    }
    if (startCompileTranslated) {
      findCompileTranslated(files)
    }
    if (startCreateExec) {
      findExecs(files)
    }
    if (startCompileProp || startProve) {
      findPropositions(files)
    }
    return () => {
      // Component unmount code.
    }
  }, [files])

  useEffect(() => {
    if (!startCreateExec) {
      findExecs(files)
    }
    if (!startCompileProp && !startProve && session.length > 0) {
      findPropositions(files)
    }
    return () => {
      // Component unmount code.
    }
  }, [meta])

  const updateNotifications = (item, show = false) => {
    notifications.push(item)
    if (item && item['data']) {
      if (item['data'].meta && item['data'].meta.length > 0) {
        let m = JSON.parse(item['data'].meta)
        setMeta(m)
        setCurContract(contract(m))
      }

      //console.log(item['data']) //!!!

      if (item['data'].files) {
        setFiles(item['data'].files)
      }
      //if (item['data'].file) {
        //let newFiles = []
        //Object.assign(newFiles, files)
        //newFiles.push(item['data'].file)
        //setFiles(newFiles)
      //}
    }
    if (show) { // из api
      setNotification(item)
    }
  }

  const findTranslate = (files_) => {
    const coqsrcPath = '/share/'+session+'/artifacts/coqsrc/'
    let status = ''
    for (let i in files_) {
      if (files_[i].event !== 'delete') {
        const iTranslate = files_[i].path.indexOf(coqsrcPath)
        if (iTranslate >= 0) {
          const extension = files_[i].path.substring(files_[i].path.lastIndexOf('.') + 1)
          if (extension === 'success') {
            status = 'success'
          }
          if (extension === 'error' || extension === 'timeout') {
            status = extension
          }
        }
      }
    }
    if (status.length === 0) {
      status = 'error'
    }
    setStatusTranslate(status)
  }

  const findExecs = (files_) => {
    let newExecs = []
    for (let key in curContract.contractMethods) {
      let execStatus = ''
      let method = curContract.contractMethods[key]
      let methodPath = paths.contract + method
      let errorPath = methodPath + '.error'
      let timeoutPath = methodPath + '.timeout'
      let successPath = methodPath + '.success'
      let iError = files_.findLastIndex(item => item.path === errorPath && item.event !== 'delete')
      let iTimeout = files_.findLastIndex(item => item.path === timeoutPath && item.event !== 'delete')
      let iSuccess = files_.findLastIndex(item => item.path === successPath && item.event !== 'delete')
      if (iError >= 0 || iTimeout >= 0) {
        if (iError > iTimeout) {
          execStatus = 'error'
        } else {
          execStatus = 'timeout'
        }
      } else {
        if (iSuccess >= 0) {
          execStatus = 'success'
        }
        /*
        let execsPath = paths.execs + method + '.vo'
        let evalsPath = paths.evals + method + '.vo'
        let iExecs = files_.findLastIndex(item => item.path === execsPath && item.event !== 'delete')
        let iEvals = files_.findLastIndex(item => item.path === evalsPath && item.event !== 'delete')

        if ( iExecs >= 0 && iEvals >= 0) {
          execStatus = 'success'
        }
        */
      }
      newExecs.push({method, status: execStatus})
    }
    setExecs(newExecs)
    findCreateExec(newExecs)
  }

  const findCreateExec = (newExecs) => {
    if (startCreateExec) {
      let status = ''
      if (newExecs.length > 0) {
        let f = newExecs.findIndex(item => item.method === dataCreateExec)
        if (f >= 0) {
          status = 'warning'
          if (newExecs[f].status !== '') {
            status = newExecs[f].status
          }
        }
      }
      setStatusCreateExec(status)
    }
  }

  const findCompileTranslated = (files_) => {
    const metaPath = '/share/'+session+'/artifacts/meta.json'
    if (files_.findLastIndex(item => item.path === metaPath && item.event !== 'delete') >= 0) {
      setStatusCompileTranslated('success')
    } else {
      setStatusCompileTranslated('error')
    }
  }

  const findPropositions = (files_) => {
    const propositionsPath = '/share/'+session+'/artifacts/propositions'
    let newPropositions= []
    // name, function, code
    for (let i in files_) {
      if (files_[i].event !== 'delete') {
        const iProposition = files_[i].path.indexOf(propositionsPath)
        if (iProposition >= 0) {
          if (files_[i].path !== propositionsPath) {
            let name = files_[i].path.substring(
              iProposition+propositionsPath.length+1,
              files_[i].path.length - 5
            )
            if (files_[i].body) {
              const body = JSON.parse(files_[i].body)
              if (isObject(body)) {
                if (body['function'] && body['text']) {
                  const func = body['function']
                  const code = body['text']
                  newPropositions.push({name, function: func, code, status: '', proof_status: ''})
                }
              }
            }
          }
        }
      }
    }
    // status && proof_status
    for (let p in newPropositions) {
      let status = ''
      let proofStatus = ''
      for (let j in files_) {
        let path = files_[j].path
        // status
        const iPropositionSpec = path.indexOf(paths.spec + newPropositions[p].name)
        if (iPropositionSpec >= 0) {
          const extension = path.substring(path.lastIndexOf('.') + 1)
          if (extension === 'success') {
            status = 'success'
          }
          if (extension === 'error' || extension === 'timeout') {
            status = extension
          }
        }
        // proof_status
        const iPropositionProofs = path.indexOf(paths.proofs + newPropositions[p].name)
        if (iPropositionProofs >= 0) {
          const extension = path.substring(path.lastIndexOf('.') + 1)
          if (extension === 'success') {
            proofStatus = 'success'
          }
          if (extension === 'error' || extension === 'timeout') {
            proofStatus = extension
          }
        }
      }
      if (status.length > 0) {
        newPropositions[p].status = status
      }
      if (proofStatus.length > 0) {
        newPropositions[p].proof_status = proofStatus
      }
    }
    findCompileProp(files_)
    findProve(files_)
    //console.log(newPropositions)
    setPropositions(newPropositions)
  }

  /*
  const findAddProposition = (files_, propositionDirectory) => {
    //console.log(files_)
    if (startAddProposition) {
      let addPropositionPath = propositionDirectory + '/' + dataAddProposition + '.json'
      //console.log(addPropositionPath)
      if (files_.findLastIndex(item => item.path === addPropositionPath && item.event !== 'delete') >=0) {
        setStatusAddProposition('success')
      } else {
        setStatusAddProposition('error')
      }
    }
  }
  */

  const findCompileProp = (files_) => {
    if (startCompileProp) {
      const compilePropPath = paths.spec + dataCompileProp
      let status = ''
      for (let i in files_) {
        if (files_[i].event !== 'delete') {
          let path = files_[i].path
          const iCompileProp = path.indexOf(compilePropPath)
          if (iCompileProp >= 0) {
            const extension = path.substring(path.lastIndexOf('.') + 1)
            if (extension === 'success') {
              status = 'success'
            }
            if (extension === 'error' || extension === 'timeout') {
              status = extension
            }
          }
        }
      }
      if (status !== '') {
        setStatusCompileProp(status)
      }
    }
  }

  const findProve = (files_) => {
    if (startProve) {
      const provePath = paths.proofs + dataProve
      let status = ''
      for (let i in files_) {
        if (files_[i].event !== 'delete') {
          let path = files_[i].path
          const iProve = path.indexOf(provePath)
          if (iProve >= 0) {
            const extension = path.substring(path.lastIndexOf('.') + 1)
            if (extension === 'success') {
              status = 'ready'
            }
            if (extension === 'error' || extension === 'timeout') {
              status = extension
            }
          }
        }
      }
      if (status !== '') {
        setStatusProve(status)
      }
    }
  }

  return (
    <div className="App">
      {/*
      <header className="App-header">
      </header>
      */}
      <Notifications
        notifications={notifications}
        notification={notification}
      />
      <Api api = {api}
           api_ = {api_}
           auth = {auth}
           runAuth = {runAuth}
           sol = {sol}
           session = {session}
           setSession = {setSession}
           updateNotifications = {updateNotifications}
           meta = {meta}
           files = {files}
           execs = {execs}
           propositions = {propositions}
           curContract = {curContract}
           startCreateExec = {startCreateExec}
           startTranslate = {startTranslate}
           startCompileTranslated = {startCompileTranslated}
           startCompileProp = {startCompileProp}
           startProve = {startProve}
      />
    </div>
  );
}

export default App;
